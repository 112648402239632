import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { common, grey } from '@mui/material/colors';


const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: common["white"],
  backgroundColor: common["black"],
  '&:hover': {
    backgroundColor: grey[700],
  },
}));

export default function PlaidButton(props: {text: string, handleClick?: () => void, isSubmit?: boolean}) {
  return (
    <Stack spacing={2} direction="row">
    {props.isSubmit? 
      (<ColorButton type="submit" variant="contained" onClick={props.handleClick} sx={{margin: '0px auto 15px auto!important'}}>{props.text}</ColorButton>) :
      (<ColorButton variant="contained" onClick={props.handleClick} sx={{margin: '0px auto 15px auto!important'}}>{props.text}</ColorButton>)
    }
      </Stack>
  );
}