export const queryKeys = {
    intentResponseData: "intentResponseData",
    personaIntentResponseData: "personaIntentResponseData",
    llmVersionData: "llmVersionData",
    intentData: "intentData",
    savedUnknownIntent: "savedUnknownIntent",
    savedChatData: "savedChatData",
    userData: "userData",
    getAccountDetails: "getAccountDetails",
    getAccountUpdates: "getAccountUpdates"
  };
  