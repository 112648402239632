import variables from 'styles/constants.module.scss';

export const customMuiStyles: {[key: string]: any} = {
    defaultBtn: {
      backgroundColor: 'transparent',
      color: `${variables.primaryColor}`
    },
    containedBtn: {
      backgroundColor: `${variables.primaryColor}`,
      '&:hover': {
        backgroundColor: `${variables.secondaryColor}`
      }
    },
    outlinedBtn: {
      backgroundColor: 'transparent',
      color: `${variables.primaryColor}`,
      borderColor: `${variables.primaryColor}`,
      '&:hover': {
        borderColor: `${variables.secondaryColor}`
      }
    },
    iconBtn: {
        backgroundColor: 'transparent',
        color: `${variables.primaryColor}`,
        borderColor: `${variables.primaryColor}`,
        '&:hover': {
          borderColor: `${variables.secondaryColor}`
        }
      },
    toggleSwitch: {
        '.MuiTouchRipple-root': {
            color: `${variables.primaryColor}`
        },
        '.Mui-checked+.MuiTouchRipple-root': {
            color: `${variables.primaryColor}`
        },
        '.MuiSwitch-thumb': {
            color: '#fff'
        },
        '.Mui-checked .MuiSwitch-thumb': {
            color: `${variables.primaryColor}`
        },
        '.MuiSwitch-track': {
            backgroundColor: '#000',
            opacity: '0.38'
        },
        '&.MuiSwitch-root .Mui-checked+.MuiSwitch-track': {
            backgroundColor: `${variables.primaryColor}`,
            opacity: '0.5'
        }
    },
    radioBtn: {
        '&.Mui-checked': {
            color: `${variables.primaryColor}`
        }
    },
    textField: {
       
    }
  }
