import { VAResponseReply } from '../../api/send-message';
import { FC, useContext } from 'react';
//import { useHttpQuery } from 'packages/http-client/react';
import { DownloadPDFContext } from '../../contexts';

export const PdfItem: FC<VAResponseReply> = (props) => {
  // const blob: Blob = useHttpQuery({
  //   method: 'GET',
  //   responseBodyType: 'blob',
  //   url: `/v2/statements/debit/${props.value.description}/statement.pdf`
  // });
  const { download } = useContext(DownloadPDFContext);

  const openPDF = async () => {
    //await download(blob);
  };
  return (
    <div className="Suggestions" key={props.id}>
      <button className="ButtonRaised Button"
        onClick={openPDF}
        key={props.id}
      ><span className="">Download Statement</span>
      </button>
    </div>
  );
};
