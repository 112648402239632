import React, { FC } from 'react';
import PlaidLogo from 'images/plaid_logo.svg'
import BankLogo from 'images/bank_logo.png';
import { Box, Card, DialogActions, FormControl, FormGroup, IconButton, Input, InputLabel, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaidButton from 'components/plaid/plaid-button';
import { z } from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAddBankAccount } from 'apis/apis';

const LinkAccountSchema = z.object({
  account_number: z
    .string()
    .refine((value) => /[1-9]/g.test(value ?? ""), 'Please enter a numeric value')
    .refine((value) => value.length === 13, 'Please enter a 13 digit number'),
  routing_number: z
    .string()
    .refine((value) => /[1-9]/g.test(value ?? ""), 'Please enter a numeric value')
    .refine((value) => value.length === 9, 'Please enter a 9 digit number'),
  account_nickname: z.string(),
}).required();

type LinkAccountSchemaType = z.infer<typeof LinkAccountSchema>;


// Styled component for header
const Header = styled(Box)({
    backgroundColor: '#1e57a5', // Blue background for the header
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '8px 8px 0 0',
  });

const PlaidLinkAccount: FC<{setShowPlaidLinkAccount: React.Dispatch<React.SetStateAction<boolean>>, bankName: string, setOpenPlaid: React.Dispatch<React.SetStateAction<boolean>> }> = (props) => {
  const addAccountMutation = useAddBankAccount((data: any) => {
    sessionStorage.setItem('linked-account-number', data['account-number']);
    props.setOpenPlaid(false);
  });

  const goBack = () => {
    props.setShowPlaidLinkAccount(false);
  }

  //React form hook
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LinkAccountSchemaType>({ resolver: zodResolver(LinkAccountSchema) });

  const onSubmit: SubmitHandler<LinkAccountSchemaType> = (data) => {
    const payload = {
      "user-id": JSON.parse(sessionStorage.getItem("user-details") || "")["user-id"],
      "name": props.bankName,
      "routing-number": data.routing_number,
      "account-number": data.account_number,
      "nickname": data.account_nickname
    }
    addAccountMutation.mutate(payload);
  }

  return (
    <div style={{ padding: '20px' }}>
      <IconButton aria-label="back" onClick={goBack} sx={{position: 'absolute', left: '5px', top: '10px'}}>
        <ArrowBackIcon />
      </IconButton>
      <img src={PlaidLogo} alt="PlainLinkLogo" width="54" style={{margin: 'auto', display: 'block'}} />
      <img src={BankLogo} alt="BankLogo" width="100" style={{margin: '30px auto', display: 'block'}} />
      <h3 style={{textAlign: 'center'}}>Link your bank account</h3>
      <Card style={{ width: '300px', borderRadius: '10px', overflow: 'hidden', marginBottom: '25px' }}>
        <Header>{props.bankName}</Header>
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
                '& > :not(style)': { m: 1, width: '35ch' },
            }}
            autoComplete="off"
            >
            <FormControl sx={{width: '282px!important'}}>
              <FormGroup>
                  <InputLabel htmlFor="account_number">Account Number</InputLabel>
                  <Input required id="account_number" aria-describedby="my-helper-text" {...register("account_number")} />
                  {errors.account_number && <span style={{'color': 'red', 'fontSize': '14px'}}>{errors.account_number.message}</span>}
              </FormGroup>
            </FormControl>
            <FormControl sx={{width: '282px!important'}}>
              <FormGroup>
                  <InputLabel htmlFor="routing_number">Routing Number</InputLabel>
                  <Input required id="routing_number" aria-describedby="my-helper-text" {...register("routing_number")} />
                  {errors.routing_number && <span style={{'color': 'red', 'fontSize': '14px'}}>{errors.routing_number.message}</span>}
              </FormGroup>
            </FormControl>
            <FormControl sx={{width: '282px!important'}}>
              <FormGroup>
                  <InputLabel htmlFor="account_nickname">Account nickname</InputLabel>
                  <Input required id="account_nickname" aria-describedby="my-helper-text" {...register("account_nickname")} />
                  {errors.account_nickname && <span style={{'color': 'red', 'fontSize': '14px'}}>{errors.account_nickname.message}</span>}
              </FormGroup>
            </FormControl>
            <DialogActions sx={{display: 'block'}}>
                <PlaidButton text="Link Account" isSubmit={true} />
            </DialogActions>
        </Box>
      </Card>
    </div>
  );
};

export default PlaidLinkAccount;







