export const submitButtonText = (_text: string, _setUserTxt: React.Dispatch<React.SetStateAction<string>>) => {
  console.log(_text);
  _setUserTxt(_text);
  setTimeout(() => {
    document.getElementById('chat-submit')?.click();
  }, 1);
}

export const mainMenu = (setUserTxt: React.Dispatch<React.SetStateAction<string>>, setWelcomeMsg: React.Dispatch<React.SetStateAction<any>>) => {
  return {
    from: 'bot',
    type: 'ACTION_BUTTON',
    value: {
      description: 'Menu=',
      action: () => {
        console.log('Menu!!');
        setWelcomeMsg(() => {
          return mainMenuActions(setUserTxt, setWelcomeMsg);
        });
      }
    },
    conversation_id: '1',
    storedInDB: false
  }
}

export const cardActions = (setUserTxt: React.Dispatch<React.SetStateAction<string>>, setWelcomeMsg: React.Dispatch<React.SetStateAction<any>>) => [
    {
      from: "user",
      type: "TEXT",
      value: "Cards",
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Replace Card=',
        action: () => {
          submitButtonText('Replace Card', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Activate Card=',
        action: () => {
          submitButtonText('Activate Card', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Enable Card=',
        action: () => {
          submitButtonText('Enable Card', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Disable Card=',
        action: () => {
          submitButtonText('Disable Card', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Shipping Card Status=',
        action: () => {
          submitButtonText('Shipping Card Status', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Cancel Card=',
        action: () => {
          submitButtonText('Cancel Card', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    mainMenu(setUserTxt, setWelcomeMsg)
  ];

  export const globalActions = (setUserTxt: React.Dispatch<React.SetStateAction<string>>, setWelcomeMsg: React.Dispatch<React.SetStateAction<any>>) => [
    {
      from: "user",
      type: "TEXT",
      value: "Global",
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Show Balance=',
        action: () => {
          submitButtonText('Show Balance', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Show Routing Number=',
        action: () => {
          submitButtonText('Show Routing Number', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    mainMenu(setUserTxt, setWelcomeMsg),
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'End Chat=',
        action: () => {
          submitButtonText('End Chat', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    }
  ];

  export const notificationActions = (setUserTxt: React.Dispatch<React.SetStateAction<string>>, setWelcomeMsg: React.Dispatch<React.SetStateAction<any>>) => [
    {
      from: "user",
      type: "TEXT",
      value: "Notifications",
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Show latest notifications=',
        action: () => {
          submitButtonText('Show latest notifications', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Manage Notifications=',
        action: () => {
          submitButtonText('Manage Notifications', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Show all Notifications=',
        action: () => {
          submitButtonText('Show all Notifications', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Sign up=',
        action: () => {
          submitButtonText('Sign up for notifications', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    mainMenu(setUserTxt, setWelcomeMsg)
  ];

  export const overdraftActions = (setUserTxt: React.Dispatch<React.SetStateAction<string>>, setWelcomeMsg: React.Dispatch<React.SetStateAction<any>>) => [
    {
      from: "user",
      type: "TEXT",
      value: "Overdraft",
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Opt out=',
        action: () => {
          submitButtonText('Opt out of overdraft', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Opt in=',
        action: () => {
          submitButtonText('Opt in for overdraft', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    mainMenu(setUserTxt, setWelcomeMsg)
  ];

  export const rewardsActions = (setUserTxt: React.Dispatch<React.SetStateAction<string>>, setWelcomeMsg: React.Dispatch<React.SetStateAction<any>>) => [
    {
      from: "user",
      type: "TEXT",
      value: "Rewards",
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Opt out=',
        action: () => {
          submitButtonText('Opt out of rewards', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Opt in=',
        action: () => {
          submitButtonText('Opt in for rewards', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'View offers=',
        action: () => {
          submitButtonText('View offers', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    mainMenu(setUserTxt, setWelcomeMsg)
  ];

  export const securityActions = (setUserTxt: React.Dispatch<React.SetStateAction<string>>, setWelcomeMsg: React.Dispatch<React.SetStateAction<any>>) => [
    {
      from: "user",
      type: "TEXT",
      value: "Security",
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Freeze Card=',
        action: () => {
          submitButtonText('Freeze Card', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Reset Pin=',
        action: () => {
          submitButtonText('Reset Pin', setUserTxt);
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    mainMenu(setUserTxt, setWelcomeMsg)
  ];

  export const mainMenuActions = (setUserTxt: React.Dispatch<React.SetStateAction<string>>, setWelcomeMsg: React.Dispatch<React.SetStateAction<any>>) => [
    {
      from: "user",
      type: "TEXT",
      value: "Menu",
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Cards=',
        action: () => {
          console.log('Cards!!');
          setWelcomeMsg(() => {
            return cardActions(setUserTxt, setWelcomeMsg);
          });
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Overdraft=',
        action: () => {
          console.log('Overdraft!!');
          setWelcomeMsg(() => {
            return overdraftActions(setUserTxt, setWelcomeMsg);
          });
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Global=',
        action: () => {
          console.log('Global!!');
          setWelcomeMsg(() => {
            return globalActions(setUserTxt, setWelcomeMsg);
          });
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Rewards=',
        action: () => {
          console.log('Rewards!!');
          setWelcomeMsg(() => {
            return rewardsActions(setUserTxt, setWelcomeMsg);
          });
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Notifications=',
        action: () => {
          console.log('Notifications!!');
          setWelcomeMsg(() => {
            return notificationActions(setUserTxt, setWelcomeMsg);
          });
        }
      },
      conversation_id: '1',
      storedInDB: false
    },
    {
      from: 'bot',
      type: 'ACTION_BUTTON',
      value: {
        description: 'Security=',
        action: () => {
          console.log('Security!!');
          setWelcomeMsg(() => {
            return securityActions(setUserTxt, setWelcomeMsg);
          });
        }
      },
      conversation_id: '1',
      storedInDB: false
    }
  ];