import { VAResponseReply } from '../../api/send-message';
import { FC } from 'react';

export const LinkItem: FC<VAResponseReply> = ({ value, id }) => {
  const [displayText, subroute] = value.description?.split('=') ?? ['', ''];
  return (
    <div className="Suggestions" key={id}>
      <a>
        <button className="ButtonFlat Button" name={displayText}>
          <span className="">{displayText}</span>
        </button>
      </a>
    </div>
  );
};
