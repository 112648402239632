//import { Body } from 'legos/typography';
import { VAResponseReply } from '../../api/send-message';
import { FC } from 'react';
//import { Content } from 'packages/react-content/macro';
import  './assistant.scss';

export const FeeTable: FC<VAResponseReply> = (props) => {
  // list from here apps/acp/angularjs-app/src/components/feeplan-domain/acp-feeplan-constant.ts
  const feePlanList = [
    'domestic_signature_purchase',
    'domestic_pin_purchase',
    'atm_decline',
    'ach_debit_decline',
    'additional_card_order',
    'atm_balance_inquiry',
    'check_request',
    'live_agent',
    'custom_card_order',
    'domestic_atm_withdrawal',
    'ivr_balance_inquiry',
    'over_the_counter',
    'replacement_card_order',
    'statement'
  ];
  return (
    <div className="txtBubble round left">
      <div className="TxtContainer">
        <div className="Body">
          <div className="Content">
            {feePlanList.map((x) => (
              <div>
                <div style={{ width: '80%', display: 'inline-block' }}>
                  {
                    props.value?.subscribed_plan_response?.plan?.fees[x]
                      ?.fee_type_description
                  }
                </div>
                <div style={{ width: '20%', display: 'inline' }}>
                  :{' '}
                  {
                    props.value?.subscribed_plan_response?.plan?.fees[x]
                      ?.flat_fee
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
