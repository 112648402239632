import { CircularProgress } from '@mui/material';
import './loader-screen.scss';
import { FC, createElement } from 'react';

export const LoaderScreen: FC = () => {
  return (
    <div className='loaderContainer'>
      <CircularProgress></CircularProgress>
    </div>
  );
};
