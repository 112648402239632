import { useContext } from 'react';
import { FileDownloaderPluginContext } from '../contexts';
// import { StatementType } from '../api/statements';

export interface Download {
  download: (
    // type: StatementType,
    // year: number,
    // month: number,
    blob: Blob
  ) => Promise<void>;
}

export const useDownloadPDF = (): Download => {
  const fileDownloadPlugin = useContext(FileDownloaderPluginContext);

  async function download(
    // type: StatementType,
    // year: number,
    // month: number,
    blob: Blob
  ) {
    // fileDownloadPlugin.downloadFile(`${type}-${year}-${month}.pdf`, blob);
    fileDownloadPlugin.downloadFile(`latest.pdf`, blob);
  }
  return { download: download };
};
