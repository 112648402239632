import React, { createContext, useContext, useState } from 'react';

export interface AccountUpdatesContextType {
  accountUpdates: any
  setAccountUpdates: React.Dispatch<React.SetStateAction<any | null>>;
}

// Create a context
const AccountUpdatesContext = createContext<AccountUpdatesContextType>(null as any);

export const useAccountUpdatesContext = () => useContext(AccountUpdatesContext);

// Create a context provider component
export const AccountUpdateProvider = ({ children }: any) => {
  const [accountUpdates, setAccountUpdates] = useState<any | null>(null);

  // The value that will be passed to the consumers
  const value = { accountUpdates, setAccountUpdates };

  return (
      <AccountUpdatesContext.Provider value={value}>
        {children}
      </AccountUpdatesContext.Provider>
    );
};
