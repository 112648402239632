import { VAResponseReply } from '../../api/send-message';
import { FC, createElement } from 'react';
import './assistant.scss';
import { UserIcon } from '../../assets/user-icon';
export const UserItem: FC<any> = ({ value, id }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center'
      }}
    >
      <UserIcon />

      <div
        className="txtBubble round right"
        key={id}
      >
        <div className='TxtContainer'>
          <div className='Body'>{value}</div>
        </div>
      </div>
    </div>
  );
};
