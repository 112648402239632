import { useState } from 'react';

function nsUtilEncodeValue(value: any) {
  const jsonString = JSON.stringify(value);
  return window.btoa(jsonString);
}

function nsUtilDecodeValue(value: string) {
  const jsonString = window.atob(value);
  return JSON.parse(jsonString);
}

interface UseStorageOptions {
  prefix?: string;
  nsUtilStyleEncoding?: boolean;
}

const OPTIONS_DEFAULTS: UseStorageOptions = {
  prefix: '',
  nsUtilStyleEncoding: false
};

/**
 * `initialValue` is not saved to storage
 */
export const createUseStorage = (
  storageType: 'local' | 'session',
  options?: UseStorageOptions
) => (key: string, initialValue: any) => {
  const storage = storageType === 'local' ? 'localStorage' : 'sessionStorage';
  const { prefix, nsUtilStyleEncoding } = {
    ...OPTIONS_DEFAULTS,
    ...options
  };

  // Based on https://usehooks.com/useLocalStorage/

  const prefixedKey = prefix + key;

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from storage by key
      const item = window[storage].getItem(prefixedKey);
      // Parse stored json or if none return initialValue
      return item
        ? (nsUtilStyleEncoding ? nsUtilDecodeValue : JSON.parse)(item)
        : initialValue;
    } catch (error) {
      // If error also return initialValue
      // eslint-disable-next-line no-console
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to storage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to storage
      window[storage].setItem(
        prefixedKey,
        (nsUtilStyleEncoding ? nsUtilEncodeValue : JSON.stringify)(valueToStore)
      );
    } catch (error) {
      // A more advanced implementation would handle the error case
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const removeValue = () => window[storage].removeItem(prefixedKey);

  return [storedValue, setValue, removeValue] as const;
};

export const useAcpLocalStorage = createUseStorage('local', {
  prefix: 'ouro_',
  nsUtilStyleEncoding: true
});
export const useAcpSessionStorage = createUseStorage('session', {
  prefix: 'ouro_',
  nsUtilStyleEncoding: true
});
