import { Dispatch, FC, SetStateAction, useEffect, useRef } from "react";
import { VAResponseReply } from "../api/send-message";
import { useSaveChats } from "apis/apis";

export const SaveTranscripts: FC<{
    storedChat: [];
    chatsToSave: any[];
    setChatsToSave:  Dispatch<SetStateAction<any[]>>;
    setEnableSaveTranscripts: Dispatch<SetStateAction<boolean>>;
    llmVersion: string;
  }> = ({ storedChat, chatsToSave, setChatsToSave, setEnableSaveTranscripts, llmVersion }): null | React.JSX.Element => {
    const createStoredChatPayload = () => {
        const chats = chatsToSave?.map((chat: VAResponseReply) => {
          return {"comingFrom": chat.from, "text": chat.from === 'bot' ? chat.value.description : chat.value};
        })
        const token = JSON.parse(localStorage.getItem('okta-token-storage') || '{}');
        return {chatbotVersion: process.env.REACT_APP_VERSION, llmVersion: llmVersion, userId: token.accessToken?.claims.uid, personaName: sessionStorage.getItem('persona'), origin: navigator.userAgent, "message": chats};
    }
  
    //Save chat transcript to DB on chat window close
    const storedChatPayload = createStoredChatPayload();
    const {mutate} = useSaveChats();
    const isChatSaved  = useRef(false);

    useEffect(() => {
      if (!isChatSaved.current) {
        isChatSaved.current = true;
        mutate(storedChatPayload);
        
        storedChat.forEach((chat: VAResponseReply) => {
          chat.storedInDB = true;
        })
        setEnableSaveTranscripts(false);
        setChatsToSave([]);
      }
      
    }, [isChatSaved, mutate, setEnableSaveTranscripts, setChatsToSave, storedChat, storedChatPayload]);
   
    return null;
  }