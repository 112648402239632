import * as React from 'react';
import VAScreen from 'components/va-screen/va-screen';
import { Fragment } from 'react';
import NavBar from 'components/nav-bar/nav-bar';
import './home.scss';
import logo from 'images/bank-logo.png';
import { useCreateProfile, useGetAccountUpdates, useUserDetails } from 'apis/apis';

// firebaseConfig.js (or firebase.js)
import { initializeApp } from 'firebase/app'; 
import { getAuth } from 'firebase/auth'; 
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useAccountUpdatesContext } from 'contexts/context';

export const Home = () => {
  const signInWithEmail = (_auth: any) => {
    signInWithEmailAndPassword(_auth, "test@gmail.com", "test@123")
      .then((userCredential) => {
        const user = userCredential.user as any;
        console.log('User signed in:', user.accessToken);
        sessionStorage.setItem('access_token', user.accessToken);
      })
      .catch((error) => {
        console.error('Error signing in:', error.message);
      });
  };

  const userDetails = useUserDetails();
  sessionStorage.setItem('user-details', JSON.stringify(userDetails));

  console.log('userDetails', userDetails);

  const createProfileMutation = useCreateProfile();
  //const displayName = JSON.parse(localStorage.getItem('okta-token-storage') || '{}').idToken.claims.name.split(' ');

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    createProfileMutation.mutate({
      "first_name": "test_fname",//displayName[0],
      "last_name": "test_lname",//displayName[1],
      "ssn": 1,
      "description": ""
    });

    // Initialize Firebase
    const firebaseConfig = {
      apiKey: "AIzaSyAo3kbQ8Zffw1G8XCJb_dTv-vS8jQUug9c",
      authDomain: "dedun-simbank-native-ui.firebaseapp.com",
      projectId: "dedun-simbank-native-ui",
      storageBucket: "dedun-simbank-native-ui.appspot.com",
      messagingSenderId: "100364697914",
      appId: "1:100364697914:web:dbab2bb5ec537cc8fd9003",
    };
    const app = initializeApp(firebaseConfig);
    // Initialize Firebase Authentication and get a reference to the service
    const auth = getAuth(app); 
    signInWithEmail(auth);
  }, []);

  const { setAccountUpdates } = useAccountUpdatesContext();
  const account_updates = useGetAccountUpdates(userDetails ? userDetails["user-id"] : {});
  setAccountUpdates(account_updates);

  return ( 
    <Fragment>
        <NavBar>
          <div className="home-cnt">
            <img alt="logo" width="250" src={logo} className="center-logo" />
          </div>
          <VAScreen />
        </NavBar>
    </Fragment>);
}
