import { FC } from 'react';
import { useDownloadPDF } from './hooks';
import { DownloadPDFContext } from './contexts';
import { VAssistant } from './va-assistant';

export const VirtualAssistant: FC<{storedChat:any; setStoredChat: (value: any) => void; llmVersion: string; userTxt:string; setUserTxt: React.Dispatch<React.SetStateAction<string>>; setWelcomeMsg:  React.Dispatch<React.SetStateAction<any[]>>}> = ({storedChat, setStoredChat, llmVersion, userTxt, setUserTxt, setWelcomeMsg}) => {
  const downloadPDF = useDownloadPDF();
  return (
    <DownloadPDFContext.Provider value={downloadPDF}>
        <VAssistant storedChat={storedChat} setStoredChat={setStoredChat} llmVersion={llmVersion} userTxt={userTxt} setUserTxt={setUserTxt} setWelcomeMsg={setWelcomeMsg} />
    </DownloadPDFContext.Provider>
  );
};
