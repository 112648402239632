const dateFormatter = Intl.DateTimeFormat('default', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
});

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

// formats the date to MM/dd/yyyy
export const formatDate = (date: string) => {
  return dateFormatter.format(
    new Date(date.substring(0, 10).replace(/-/g, '/'))
  );
};

const longDateFormatter = Intl.DateTimeFormat('default', {
  day: 'numeric',
  month: 'long',
  year: 'numeric'
});

const timeFormatter = Intl.DateTimeFormat('default', {
  hour: 'numeric',
  minute: 'numeric'
});

export const getformattedTime = (date: string) => {
  const dateWithoutDashes =
    date.substring(0, 10).replace(/-/g, '/') + date.substring(10);
  return timeFormatter.format(new Date(dateWithoutDashes));
};

export const getLongDate = (date: string) => {
  const dateWithoutDashes =
    date.substring(0, 10).replace(/-/g, '/') + date.substring(10);
  return longDateFormatter.format(new Date(dateWithoutDashes));
};

const shortDateFormatter = new Intl.DateTimeFormat('default', {
  month: '2-digit',
  day: '2-digit',
  year: '2-digit'
});

export const getShortDate = (date: string) => {
  const dateWithoutDashes =
    date.substring(0, 10).replace(/-/g, '/') + date.substring(10);
  return shortDateFormatter.format(new Date(dateWithoutDashes));
};

export const getDateFormatWithComma = (date: string) => {
  const dateString = new Date(date);
  return (
    months[dateString.getMonth()] +
    ' ' +
    dateString.getDate() +
    ',' +
    ' ' +
    dateString.getFullYear()
  );
};

export const getDateWithOnlyMonthAndYear = (date: string) => {
  const dateString = new Date(date);
  return months[dateString.getMonth()] + ' ' + dateString.getFullYear();
};

export const convertDateToMilliseconds = (dateTime: string) => {
  // 09-29-2015 14:12:59 -0500

  let tmpSplit, year, month, day, time, timeZone, timeStamp;

  try {
    tmpSplit = dateTime.split(' ');

    month = tmpSplit[0].split('-')[0].trim();
    day = tmpSplit[0].split('-')[1].trim();
    year = tmpSplit[0].split('-')[2].trim();

    time = tmpSplit[1] ? tmpSplit[1] : '';
    timeZone = tmpSplit[2] ? tmpSplit[2] : '';

    timeStamp = month + '/' + day + '/' + year + ' ' + time + ' GMT' + timeZone;
  } catch (err) {
    return 0;
  }

  return Date.parse(timeStamp);
};

/**
 * Return dob in format mm/dd/yyyy
 * @param dob dob string in format mmddyyyy
 * @returns formatted dob in mm/dd/yyyy
 */
export const dobFormatter = (dob: string) => {
  if (dob.length !== 8) throw new Error('Incorrect dob');
  return `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}`;
};

/**
 * Time with AM or PM
 */
const timeWithMeridiem = new Intl.DateTimeFormat('default', {
  hour12: true,
  hour: 'numeric',
  minute: 'numeric'
});

const timeZone = new Intl.DateTimeFormat('default', {
  timeZoneName: 'long'
});

/**
 * Return TimeZone Abbreviations Like eg. Indian Standard Time to IST
 */
const getFormattedTimeZoneAbbreviated = (tz: string): string => {
  // In Chrome browser, new Date().toString() is
  // "Thu Aug 06 2020 16:21:38 GMT+0530 (India Standard Time)"

  // In Safari browser, new Date().toString() is
  // "Thu Aug 06 2020 16:24:03 GMT+0530 (IST)"
  if (tz.includes(' ')) {
    return tz
      .split(' ')
      .map(([first]: string) => first)
      .join('');
  } else {
    return tz;
  }
};

/**
 * Return Date Time with Meridiem and Time Zone in Short
 * Like Eg. Submitted on 12/04/2023 at 2:11pm IST
 */
export const getformattedDateTimeMeridiemWithTimeZone = (dateTime: string) => {
  const dateWithoutDashes =
    dateTime.substring(0, 10).replace(/-/g, '/') + dateTime.substring(10);
  const date = dateFormatter.format(new Date(dateWithoutDashes));
  const meridiem = timeWithMeridiem.format(new Date(dateWithoutDashes));
  const [, timezone] = timeZone.format(new Date(dateWithoutDashes)).split(',');
  const timeZoneAbbreviated = getFormattedTimeZoneAbbreviated(timezone);
  return date.concat(' ', meridiem, ' ', timeZoneAbbreviated);
};

const MonthDayDateFormatter = new Intl.DateTimeFormat('default', {
  month: 'short',
  day: 'numeric'
});

/**
 * Return Date With Month
 * Like Eg. Jun 9
 */
export const getDateWithDayMonthOnly = (date: string) => {
  const dateWithoutDashes =
    date.substring(0, 10).replace(/-/g, '/') + date.substring(10);
  return MonthDayDateFormatter.format(new Date(dateWithoutDashes));
};

const dayMonthFormat = new Intl.DateTimeFormat('default', {
  day: '2-digit'
});

/**
 * Return Date
 * Like Eg. 12 or 19 as number format
 */
export const getDate = (date: string) => {
  const dateWithoutDashes =
    date.substring(0, 10).replace(/-/g, '/') + date.substring(10);
  return parseInt(dayMonthFormat.format(new Date(dateWithoutDashes)));
};

const time = new Intl.DateTimeFormat('default', {
  hour12: true,
  hour: '2-digit',
  minute: '2-digit'
});
/**
 * Return Time with Meridiem
 * Like Eg. 12:00 AM
 */
export const getTimeWithHHMM = (date: string) => {
  const dateWithoutDashes =
    date.substring(0, 10).replace(/-/g, '/') + date.substring(10);
  return time.format(new Date(dateWithoutDashes));
};

const DateWithDayMonthYear = new Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: '2-digit',
  year: 'numeric'
});

/**
 * Return Date With Month
 * Like Eg. Jun 9, 2023
 */
export const getDateWithDayMonthYear = (date: string) => {
  const dateWithoutDashes =
    date.substring(0, 10).replace(/-/g, '/') + date.substring(10);
  return DateWithDayMonthYear.format(new Date(dateWithoutDashes));
};
