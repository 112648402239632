import { FC } from 'react';
import { VAResponseReply } from '../../api/send-message';
import { TextItem } from './text-item';
import { UserItem } from './user-item';
import { LinkItem } from './link-item';
import { PdfItem } from './pdf-item';
import { TransactionsTable } from './transactions-table-item';
import { FeeTable } from './fee-table-tem';
import { ActionButton } from './action-button';

export const ChatBubble: FC<VAResponseReply> = (props) => {
  return <ChatBubbleSwitch {...props} key={props.id} />;
};

const ChatBubbleSwitch: FC<VAResponseReply> = (props) => {
  switch (props.type) {
    case 'TEXT':
      return props.from === 'bot' ? TextItem(props) : UserItem(props);
    case 'LINK':
      return LinkItem(props);
    case 'PDF':
      return PdfItem(props);
    case 'TRANSACTIONS_TABLE':
      return TransactionsTable(props);
    case 'FEE_TABLE':
      return FeeTable(props);
    case 'ACTION_BUTTON':
      return ActionButton(props);
    default:
      return null;
  }
};
