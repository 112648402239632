import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Card, CardContent, FormControl, FormGroup, IconButton, Input, InputLabel, Typography } from "@mui/material";
import NavBar from "components/nav-bar/nav-bar";
import VAScreen from "components/va-screen/va-screen";
import { Fragment, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { customMuiStyles } from "styles/mui-custom-styles";
import z from "zod";

const SaveEmailSchema = z.object({
    email: z.string().email()}).required();

const SaveAddressSchema = z.object({
    address: z.object({
        address1: z.string(),
        address2: z.string(),
        city: z
            .string()
            .refine((value) => /^[a-zA-Z]*$/g.test(value ?? ""), 'Please enter alphabets'),
        state: z
            .string()
            .refine((value) => /^[a-zA-Z]*$/g.test(value ?? ""), 'Please enter alphabets'),
        country: z
            .string()
            .refine((value) => /^[a-zA-Z]*$/g.test(value ?? ""), 'Please enter alphabets'),
        zip: z
            .string()
            .refine((value) => /[0-9]/g.test(value ?? ""), 'Please enter a numeric value')
            .refine((value) => value.length === 5, 'Please enter a 5 digit ZIP')    
    })
  }).required();

const SavePhoneSchema = z.object({
    phone: z
        .string()
        .refine((value) => /[0-9]/g.test(value ?? ""), 'Please enter a numeric value')
        .refine((value) => value.length === 10, 'Please enter a 10 digit number')
  }).required();   

type SaveEmailSchemaType = z.infer<typeof SaveEmailSchema>;
type SaveAddressSchemaType = z.infer<typeof SaveAddressSchema>;
type SavePhoneSchemaType = z.infer<typeof SavePhoneSchema>;

export const Profile = () => {
    const [isEditing, setIsEditing] = useState({
        "email": false,
        "address": false,
        "phone": false,
        "zip": false
    });

    const editForm = (field: string) => {
        setIsEditing((prevValue) => {
            return {...prevValue, [field]: true}
        })
    }
    const cancelEdit = (field: string) => {
        setIsEditing((prevValue) => {
            return {...prevValue, [field]: false}
        })
    }

    //React form hook
    const {
        register: registerEmail,
        handleSubmit: handleEmailSubmit,
        formState: { errors: emailErrors }
    } = useForm<SaveEmailSchemaType>({ resolver: zodResolver(SaveEmailSchema) });

    //React form hook
    const {
        register: registerAddress,
        handleSubmit: handleAddressSubmit,
        formState: { errors: addressErrors }
        } = useForm<SaveAddressSchemaType>({ resolver: zodResolver(SaveAddressSchema) });

    //React form hook
    const {
        register: registerPhone,
        handleSubmit: handlePhoneSubmit,
        formState: { errors: phoneErrors }
        } = useForm<SavePhoneSchemaType>({ resolver: zodResolver(SavePhoneSchema) });

    const onEmailSubmit: SubmitHandler<SaveEmailSchemaType> = (data) => {
        setIsEditing((prevValue) => {
            return {...prevValue, "email": false}
        })
        console.log('Submitted Email!!', data);
    }

    const onAddressSubmit: SubmitHandler<SaveAddressSchemaType> = (data) => {
        setIsEditing((prevValue) => {
            return {...prevValue, "address": false}
        })
        console.log('Submitted Address!!', data);
    }

    const onPhoneSubmit: SubmitHandler<SavePhoneSchemaType> = (data) => {
        setIsEditing((prevValue) => {
            return {...prevValue, "phone": false}
        })
        console.log('Submitted Phone!!', data);
    }

    return (
    <Fragment>
        <NavBar>
            <VAScreen />
            <Typography variant="h5" component="h5" mt={1} mb={1} ml={2}>Profile</Typography>
            <Box
                component="div"
                id="profile"
                sx={{
                marginTop:'42px',
                '& > :not(style)': {width: 'auto' }
                }}
            >
                <Card variant="outlined">
                    <CardContent>
                        <div className="editable-form-field">
                            <label><strong>First Name</strong></label>
                            <label>Shivam</label>
                        </div>
                        <div className="editable-form-field">
                            <label><strong>Last Name</strong></label>
                            <label>Bahuguna</label>
                        </div>
                    {isEditing["email"] ? (
                        <form onSubmit={handleEmailSubmit(onEmailSubmit)}>
                            <div className="editable-form-field">
                                <label><strong>Email</strong></label>
                                <FormControl>
                                    <FormGroup>
                                        <InputLabel htmlFor="email">Enter Email</InputLabel>
                                        <Input required id="email" aria-describedby="my-helper-text" {...registerEmail("email")} />
                                        {emailErrors.email && <span style={{'color': 'red', 'fontSize': '14px'}}>{emailErrors.email.message}</span>}
                                    </FormGroup>
                                </FormControl>
                                <IconButton onClick={()=>cancelEdit("email")} sx={customMuiStyles.iconBtn} style={{padding: '4px', margin: '0 6px'}} aria-label="cancel-email"><CloseIcon sx={{fontSize:'24px'}} /></IconButton>
                                <IconButton type="submit" sx={customMuiStyles.iconBtn} style={{padding: '4px', margin: '0 6px'}} aria-label="submit-email"><DoneIcon sx={{fontSize:'24px'}} /></IconButton>
                            </div>
                        </form>) :(
                        <div className="editable-form-field">
                            <label><strong>Email</strong></label>
                            <label className="form-display-value">sbahuguna@ouro.com</label>
                            <IconButton onClick={() => editForm("email")} sx={customMuiStyles.iconBtn} style={{padding: '4px', margin: '0 6px'}} aria-label="edit-email"><EditIcon sx={{fontSize:'18px'}} /></IconButton>
                        </div>
                        )
                    }
                    {isEditing["address"] ? (
                        <form onSubmit={handleAddressSubmit(onAddressSubmit)}>
                            <div className="editable-form-field">
                                <label><strong>Address</strong></label>
                                <FormControl>
                                    <FormGroup>
                                        <InputLabel htmlFor="address1">Enter Address1</InputLabel>
                                        <Input multiline={true} required id="address1" aria-describedby="my-helper-text" {...registerAddress("address.address1")} />
                                        {addressErrors.address?.address1 && <span style={{'color': 'red', 'fontSize': '14px'}}>{addressErrors.address.address1.message}</span>}
                                    </FormGroup>
                                </FormControl>
                                <IconButton onClick={()=>cancelEdit("address")} sx={customMuiStyles.iconBtn} style={{padding: '4px', margin: '0 6px'}} aria-label="cancel-address"><CloseIcon sx={{fontSize:'24px'}} /></IconButton>
                                <IconButton type="submit" sx={customMuiStyles.iconBtn} style={{padding: '4px', margin: '0 6px'}} aria-label="submit-address"><DoneIcon sx={{fontSize:'24px'}} /></IconButton>
                            </div>
                            <div className="editable-form-field">
                                <label></label>
                                <FormControl>
                                    <FormGroup>
                                        <InputLabel htmlFor="address2">Enter Address2</InputLabel>
                                        <Input multiline={true} required id="address2" aria-describedby="my-helper-text" {...registerAddress("address.address2")} />
                                        {addressErrors.address?.address2 && <span style={{'color': 'red', 'fontSize': '14px'}}>{addressErrors.address.address2.message}</span>}
                                    </FormGroup>
                                </FormControl>
                            </div>
                            <div className="editable-form-field">
                                <label></label>
                                <FormControl>
                                    <FormGroup>
                                        <InputLabel htmlFor="city">Enter City</InputLabel>
                                        <Input multiline={true} required id="city" aria-describedby="my-helper-text" {...registerAddress("address.city")} />
                                        {addressErrors.address?.city && <span style={{'color': 'red', 'fontSize': '14px'}}>{addressErrors.address.city.message}</span>}
                                    </FormGroup>
                                </FormControl>
                            </div>
                            <div className="editable-form-field">
                                <label></label>
                                <FormControl>
                                    <FormGroup>
                                        <InputLabel htmlFor="state">Enter State</InputLabel>
                                        <Input multiline={true} required id="state" aria-describedby="my-helper-text" {...registerAddress("address.state")} />
                                        {addressErrors.address?.state && <span style={{'color': 'red', 'fontSize': '14px'}}>{addressErrors.address.state.message}</span>}
                                    </FormGroup>
                                </FormControl>
                            </div>
                            <div className="editable-form-field">
                                <label></label>
                                <FormControl>
                                    <FormGroup>
                                        <InputLabel htmlFor="country">Enter Country</InputLabel>
                                        <Input multiline={true} required id="country" aria-describedby="my-helper-text" {...registerAddress("address.country")} />
                                        {addressErrors.address?.country && <span style={{'color': 'red', 'fontSize': '14px'}}>{addressErrors.address.country.message}</span>}
                                    </FormGroup>
                                </FormControl>
                            </div>
                            <div className="editable-form-field">
                                <label></label>
                                <FormControl>
                                    <FormGroup>
                                        <InputLabel htmlFor="zip">Enter Zip Code</InputLabel>
                                        <Input multiline={true} required id="zip" aria-describedby="my-helper-text" {...registerAddress("address.zip")} />
                                        {addressErrors.address?.zip && <span style={{'color': 'red', 'fontSize': '14px'}}>{addressErrors.address.zip.message}</span>}
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </form>) :(
                            <>
                                <div className="editable-form-field">
                                    <label><strong>Address</strong></label>
                                    <label className="form-display-value">XYZ 1234 XYZ 1234 XYZ 1234 XYZ</label>
                                    <IconButton onClick={() => editForm("address")} sx={customMuiStyles.iconBtn} style={{padding: '4px', margin: '0 6px'}} aria-label="edit-address"><EditIcon sx={{fontSize:'18px'}} /></IconButton>
                                </div>
                                <div className="editable-form-field">
                                    <label></label>
                                    <label>ABCD1234</label>
                                </div>
                                <div className="editable-form-field">
                                    <label></label>
                                    <label>New Delhi</label>
                                </div>
                                <div className="editable-form-field">
                                    <label></label>
                                    <label>Delhi</label>
                                </div>
                                <div className="editable-form-field">
                                    <label></label>
                                    <label>India</label>
                                </div>
                                <div className="editable-form-field">
                                    <label></label>
                                    <label>24811</label>
                                </div>
                            </>
                        )
                    }
                    {isEditing["phone"] ? (
                        <form onSubmit={handlePhoneSubmit(onPhoneSubmit)}>
                            <div className="editable-form-field">
                                <label><strong>Phone Number</strong></label>
                                <FormControl>
                                    <FormGroup>
                                        <InputLabel htmlFor="phone">Enter Phone Number</InputLabel>
                                        <Input required id="phone" aria-describedby="my-helper-text" {...registerPhone("phone")} />
                                        {phoneErrors.phone && <span style={{'color': 'red', 'fontSize': '14px'}}>{phoneErrors.phone.message}</span>}
                                    </FormGroup>
                                </FormControl>
                                <IconButton onClick={()=>cancelEdit("phone")} sx={customMuiStyles.iconBtn} style={{padding: '4px', margin: '0 6px'}} aria-label="cancel-phone"><CloseIcon sx={{fontSize:'24px'}} /></IconButton>
                                <IconButton type="submit" sx={customMuiStyles.iconBtn} style={{padding: '4px', margin: '0 6px'}} aria-label="submit-phone"><DoneIcon sx={{fontSize:'24px'}} /></IconButton>
                            </div>
                        </form>) :(
                                <div className="editable-form-field">
                                <label><strong>Phone Number</strong></label>
                                <label className="form-display-value">9923027939</label>
                                <IconButton onClick={() => editForm("phone")} sx={customMuiStyles.iconBtn} style={{padding: '4px', margin: '0 6px'}} aria-label="edit-phone"><EditIcon sx={{fontSize:'18px'}} /></IconButton>
                            </div>
                        )
                    }
                    {/* <div style={{'float': 'right', display: 'flex', gap: '10px', right: '10px', position: 'absolute'}}>
                        <Button sx={customMuiStyles.defaultBtn} onClick={()=> setIsEditing(false)}>Cancel</Button>
                        <Button type="submit" sx={customMuiStyles.containedBtn} variant="contained">Save</Button>
                    </div> */}
                    </CardContent>
                </Card>
            </Box>
        </NavBar>
    </Fragment>)
}