import { useEffect, useState } from 'react';
import variables from 'styles/constants.module.scss';
import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BankLogo from 'images/bank-logo.png';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import DoorBackOutlinedIcon from '@mui/icons-material/DoorBackOutlined';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import "./nav-bar.scss";
import { Link, useLocation } from 'react-router-dom';
import { isMobileWidth } from 'utils/utils';
import BottomNav from 'components/bottom-nav/bottom-nav';
import { withOktaAuth } from '@okta/okta-react';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const NavBar = (props: any) => {
  //OKTA logout
  const logout = () => {
    sessionStorage.clear();
    props.oktaAuth.signOut();
  }


  const theme = useTheme();
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [isMobile, setIsMobile] = useState(isMobileWidth());

  useEffect(() => {
    function handleWindowResize() {
      setIsMobile(isMobileWidth());
    }

    window.addEventListener('resize', handleWindowResize);

    if (!sessionStorage.getItem('persona')) sessionStorage.setItem('persona', 'Loyal Customer');

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const ListItem = styled(MuiListItem)(`
    &.Mui-selected .MuiListItemIcon-root{
      color: ${variables.primaryColor};
    }
  `);

  const styles: {[key: string]: any} = {
    appBar: {
      backgroundColor: '#fff'
    },
    navigationSection: {
      backgroundColor: '#D9D9D9',
      height: '100%'
    }
  }

  return isMobile ? (
      <BottomNav oktaAuth={props.oktaAuth}>{props.children}</BottomNav>
      ): (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={styles.appBar} color="transparent" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),

              }}
              style={{'padding': '8px', 'marginRight': '40px'}}
            >
              <MenuIcon />
            </IconButton>
            <img src={BankLogo} alt="logo" width="200"/>
            <span className="heading">A Simulated Banking Experience</span>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List className="navigation-section" sx={styles.navigationSection}>
            {[{text:'Home', path:''}, {text:'Transactions', path:'transactions'}, {text:'Settings', path:'settings'}, {text:'Back Door', path:'back-door'}, {text: 'Profile', path:'profile'}, {text:'Logout', path:'login/callback'}].map((menu, index) => (
              <ListItem key={menu.text} selected={"/" + menu.path === pathname} disablePadding sx={{ display: 'block' }} component={Link} to={"/" + menu.path}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5
                  }}
                  onClick={menu.text === 'Logout'? logout: () => null}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {index === 0 ? <HomeIcon /> : index === 1 ? <ReceiptLongIcon /> : index === 2 ? <SettingsIcon/> : index === 3 ? <DoorBackOutlinedIcon /> :  index === 4 ? <PersonIcon /> :  <LogoutIcon />}
                  </ListItemIcon>
                  <ListItemText primary={menu.text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {props.children}
        </Box>
      </Box>
    );
}

export default withOktaAuth(NavBar)
