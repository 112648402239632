import React, { FC, Fragment, useState } from 'react';
import BankOfAmericaLogo from 'images/bank_of_america_logo.png';
import ChaseBankLogo from 'images/chase_logo.png';
import WellsFargoLogo from 'images/wells_fargo_logo.png';
import CitibankLogo from 'images/citibank_logo.png';
import AmexLogo from 'images/amex_logo.png';
import UsaaLogo from 'images/usaa_logo.png';
import PlaidLogo from 'images/plaid_logo.svg'
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaidBankLogin from './plaid-login';

// List of banks with names and logos
const bankData = [
  { name: 'Bank of America', logo: BankOfAmericaLogo },
  { name: 'Chase Bank', logo: ChaseBankLogo },
  { name: 'Wells Fargo', logo: WellsFargoLogo },
  { name: 'Citibank', logo: CitibankLogo },
  { name: 'American Express', logo: AmexLogo },
  { name: 'USAA', logo: UsaaLogo }
];

const PlaidBankSearch: FC<{setShowbanks: React.Dispatch<React.SetStateAction<boolean>>; setOpenPlaid: React.Dispatch<React.SetStateAction<boolean>>}> = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showPlaidLogin, setShowPlaidLogin] = useState(false);
  const [bankName, setBankName] = useState('');

  // Filter banks based on search term
  const filteredBanks = bankData.filter(bank =>
    bank.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const goBack = () => {
    props.setShowbanks(false);
  }

  const gotoPlaidLogin = (_bankName: string) => {
    setBankName(_bankName);
    setShowPlaidLogin(true);
  }

  return (
    <Fragment>
    { showPlaidLogin ? (<PlaidBankLogin bankName={bankName} setShowPlaidLogin={setShowPlaidLogin} setOpenPlaid={props.setOpenPlaid} />) : (
    <div style={{ padding: '20px' }}>
      <IconButton aria-label="back" onClick={goBack} sx={{position: 'absolute', left: '5px', top: '10px'}}>
        <ArrowBackIcon />
      </IconButton>
      <img src={PlaidLogo} alt="PlainLinkLogo" width="54" style={{margin: 'auto', display: 'block'}} />
      <h2>Select your Bank</h2>
      {/* Search input */}
      <input
        type="text"
        placeholder="Search banks..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ padding: '10px', width: '300px', marginBottom: '20px' }}
      />
      {/* Display filtered list of banks */}
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {filteredBanks.length > 0 ? (
          filteredBanks.map((bank, index) => (
            <li key={index} 
              style={{cursor: 'pointer', display: 'flex', minHeight: '50px', padding: '10px' ,alignItems: 'center', marginBottom: '25px', borderRadius: '5px', transition: 'background-color 0.3s ease' }} 
              onClick={() => gotoPlaidLogin(bank.name)}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'white')}
              >
              <img src={bank.logo} alt={bank.name} style={{ width: '50px', marginRight: '30px' }} />
              <span>{bank.name}</span>
            </li>
          ))
        ) : (
          <li>No banks found</li>
        )}
      </ul>
    </div>)
      }
  </Fragment>
    );
};

export default PlaidBankSearch;
