import 'styles/main.scss';
import { Home } from 'pages/home/home';
import { TransactionList } from 'pages/transactions-list/transaction-list';
import { Settings } from 'pages/settings/settings';
import {BackDoor} from 'pages/back-door/back-door';
import {Profile} from 'pages/profile/profile';
import { useNavigate } from "react-router"; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from 'pages/no-page/no-page';
import { useEffect } from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import PrivateRoutes from 'components/PrivateRoutes';
import {
  QueryClientProvider
} from '@tanstack/react-query';
import { queryClient } from 'react-query/queryClient';
import { AccountUpdateProvider } from 'contexts/context';


const withRouter = (Component) => {
	const Wrapper = (props) => {
		const history = useNavigate();
		return <Component history={history} {...props} />;
	};
	return Wrapper;
};

const oktaAuth = new OktaAuth({
  issuer: 'https://netspend-sso.okta.com',
  clientId: '0oahquy85fp9KOcNN697', //aws, 0oado5lpi7Pw9BLrP697 // gcp
  redirectUri: window.location.origin + '/login/callback',
  pkce: false,
  scopes: ['openid', 'profile', 'email'],
});

// Subscribed to authState change event
oktaAuth.authStateManager.subscribe(function(authState) {
  if (!authState.isAuthenticated) {
    sessionStorage.clear();
  }
});

const RouterApp = (props) => {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {replace: true});
  }

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AccountUpdateProvider>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route index element={<Home />} />
              <Route path="transactions" element={<TransactionList />} />
              <Route path="back-door" element={<BackDoor />} />
              <Route path="profile" element={<Profile />} />
              <Route path="*" element={<NoPage />} />
            </Route>
            <Route path="/login/callback" element={<LoginCallback />}/>
            <Route path="settings" element={<Settings />} />
          </Routes>
        </Security>
      </AccountUpdateProvider>
    </QueryClientProvider>
  );
}

const AppWithRouterAccess = withRouter(RouterApp);

const App = () => (<BrowserRouter><AppWithRouterAccess/></BrowserRouter>);

export default App;
