import * as React from 'react';
import { Fragment } from 'react';
import NavBar from 'components/nav-bar/nav-bar';
import { useForm, SubmitHandler } from "react-hook-form";
import Accordion from '@mui/material/Accordion';
import Switch from '@mui/material/Switch';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionActions, Box, Button, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Input, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Typography } from '@mui/material';
import { PaymentTypes, PurchaseTypes, UserTypes } from 'types';
import { customMuiStyles } from 'styles/mui-custom-styles';
import UserConfigTable from 'components/user-config-table/user-config-table';
import { z } from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import VAScreen from 'components/va-screen/va-screen';
import styles from './back-door.module.scss';

const AddCardSchema = z.object({
  name: z.string(),
  zip: z
    .string()
    .refine((value) => /[1-9]/g.test(value ?? ""), 'Please enter a numeric value')
    .refine((value) => value.length === 5, 'Please enter a 5 digit number'),
  card_nickname: z.string(),
}).required();

type AddCardSchemaType = z.infer<typeof AddCardSchema>;

export const BackDoor = () => {
  const [userType, setUserType] = React.useState(sessionStorage.getItem('user_type') || UserTypes.DEFAULT);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [transaction, setTransaction] = React.useState<string>('Transaction history 1');
  const [card, setCard] = React.useState<string>('Card 1');
  const [disableActions, setDisableActions] = React.useState(false); 

  const handleAccChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string);
    sessionStorage.setItem('user_type', event.target.value);
  };

  const handlePurchaseChange = (event: SelectChangeEvent) => {
    //setUserType(event.target.value as string);
  };

  const handlePaymentChange = (event: SelectChangeEvent) => {
    //setUserType(event.target.value as string);
  };

  const handleTransactionChange = (event: SelectChangeEvent) => {
    setTransaction(event.target.value as string);
  };
  
  const handleCardChange = (event: SelectChangeEvent) => {
    setCard(event.target.value as string);
  };
  
  //React form hook
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AddCardSchemaType>({ resolver: zodResolver(AddCardSchema) });

  const onSubmit: SubmitHandler<AddCardSchemaType> = (data) => console.log('Submitted!!', data);
  
  return ( 
    <Fragment>
        <NavBar>
          <VAScreen />
          <Typography variant="h5" component="h5" mt={1} mb={1} ml={2}>Simulate Real-world Scenarios</Typography>
          <div className={styles.actionsContainer}>
            <Box sx={{ minWidth: 120, marginTop: 3, marginBottom: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="user-type-select-label">Reset Data to User Type</InputLabel>
                <Select
                  labelId="user-type-select-label"
                  id="user-type-select"
                  value={userType}
                  label="Reset Data to User Type"
                  onChange={handleChange}
                >
                  <MenuItem value={UserTypes.DEFAULT}>{UserTypes.DEFAULT}</MenuItem>
                  <MenuItem value={UserTypes.HEAVY_OVERDRAFT_USER}>{UserTypes.HEAVY_OVERDRAFT_USER}</MenuItem>
                  <MenuItem value={UserTypes.FRAUDSTER}>{UserTypes.FRAUDSTER}</MenuItem>
                  <MenuItem value={UserTypes.BUDGET_CONCIOUS_USER}>{UserTypes.BUDGET_CONCIOUS_USER}</MenuItem>
                  <MenuItem value={UserTypes.REWARD_SEEKER}>{UserTypes.REWARD_SEEKER}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/* <DialogSelect btnText="Change User Type" defaultValue="Loyal Customer" options={["Heavy Overdraft User", "Fraudster", "Budget-Conscious User", "Reward Seeker"]} category="User Type"/> */}
            <Accordion expanded={expanded === 'panel0'} onChange={handleAccChange('panel0')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel0-content"
                  id="panel0-header"
                >
                <strong>Order a new card via Netspend App</strong>
                </AccordionSummary>
                <AccordionDetails>
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                      '& > :not(style)': { m: 1, width: '35ch' },
                    }}
                    autoComplete="off"
                  >
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="name">Name on Card</InputLabel>
                      <Input required id="name" aria-describedby="my-helper-text" {...register("name")} />
                      {errors.name && <span style={{'color': 'red', 'fontSize': '14px'}}>{errors.name.message}</span>}
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="zip">Zip code</InputLabel>
                      <Input required id="zip" aria-describedby="my-helper-text" {...register("zip")} />
                      {errors.zip && <span style={{'color': 'red', 'fontSize': '14px'}}>{errors.zip.message}</span>}
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="card_nickname">Card nickname</InputLabel>
                      <Input required id="card_nickname" aria-describedby="my-helper-text" {...register("card_nickname")} />
                      {errors.card_nickname && <span style={{'color': 'red', 'fontSize': '14px'}}>{errors.card_nickname.message}</span>}
                    </FormGroup>
                  </FormControl>
                  <AccordionActions sx={{'float': 'right'}}>
                    <Button sx={customMuiStyles.defaultBtn} onClick={()=> setExpanded(false)}>Cancel</Button>
                    <Button type="submit" sx={customMuiStyles.containedBtn} variant="contained">Order Card</Button>
                  </AccordionActions>
                </Box>
              </AccordionDetails>
              
            </Accordion>
            <Accordion disabled={disableActions} defaultExpanded expanded={expanded === 'panel1'} onChange={handleAccChange('panel1')}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                <strong>Make a Purchase</strong>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl>
                    <FormGroup className="select-group">
                      <InputLabel htmlFor="purchase-type">Purchase Type</InputLabel>
                      <Select
                        labelId="purchase-type-select-label"
                        id="purchase-type"
                        value={PurchaseTypes.ONLINE_PURCHASE}
                        label="Select Purchase Type"
                        onChange={handlePurchaseChange}
                      >
                        <MenuItem value={PurchaseTypes.ONLINE_PURCHASE}>{PurchaseTypes.ONLINE_PURCHASE}</MenuItem>
                        <MenuItem value={PurchaseTypes.IN_STORE}>{PurchaseTypes.IN_STORE}</MenuItem>
                      </Select>
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup className="select-group">
                      <InputLabel htmlFor="card-type">Select a Card</InputLabel>
                      <Select
                        labelId="card-type-select-label"
                        id="card-type"
                        value={card}
                        label="Select Card"
                        onChange={handleCardChange}
                      >
                        <MenuItem value="Card 1">Card 1</MenuItem>
                        <MenuItem value="Card 2">Card 2</MenuItem>
                      </Select>
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="purchase_amount">Amount</InputLabel>
                      <Input id="purchase_amount" aria-describedby="my-helper-text" startAdornment={<InputAdornment position="start">$</InputAdornment>} />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="purchase_merchant">Merchant</InputLabel>
                      <Input id="purchase_merchant" aria-describedby="my-helper-text" />
                    </FormGroup>
                  </FormControl>
                 
                </Box>
              </AccordionDetails>
              <AccordionActions>
                <Button sx={customMuiStyles.defaultBtn}>Cancel</Button>
                <Button sx={customMuiStyles.containedBtn} variant="contained">Make a transaction</Button>
              </AccordionActions>
            </Accordion>
            <Accordion disabled={disableActions} expanded={expanded === 'panel2'} onChange={handleAccChange('panel2')}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                <strong>Withdraw Cash from ATM</strong>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '35ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                  {/* <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="withdraw-type">Withdraw Type</InputLabel>
                      <Select
                        labelId="withdraw-type-select-label"
                        id="withdraw-type"
                        value="atm withdrawal"
                        label="Select Transfer Type"
                      >
                        <MenuItem value="atm withdrawal">ATM Withdrawal</MenuItem>
                      </Select>
                    </FormGroup>
                  </FormControl> */}
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="withdraw_amount">Amount</InputLabel>
                      <Input id="withdraw_amount" aria-describedby="my-helper-text" startAdornment={<InputAdornment position="start">$</InputAdornment>} />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="withdraw_location">Location</InputLabel>
                      <Input id="withdraw_location" aria-describedby="my-helper-text" />
                    </FormGroup>
                  </FormControl>
                </Box>
              </AccordionDetails>
              <AccordionActions>
                <Button sx={customMuiStyles.defaultBtn}>Cancel</Button>
                <Button sx={customMuiStyles.containedBtn} variant="contained">Withdraw</Button>
              </AccordionActions>
            </Accordion>
            <Accordion disabled={disableActions} expanded={expanded === 'panel3'} onChange={handleAccChange('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <strong>Bill Payments</strong>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl>
                    <FormGroup className="select-group">
                      <InputLabel htmlFor="payment-type">Payment Type</InputLabel>
                      <Select
                        labelId="payment-type-select-label"
                        id="payment-type"
                        value={PaymentTypes.PAY_UTILITY_BILLS}
                        label="Select Payment Type"
                        onChange={handlePaymentChange}
                      >
                        <MenuItem value={PaymentTypes.PAY_UTILITY_BILLS}>{PaymentTypes.PAY_UTILITY_BILLS}</MenuItem>
                      </Select>
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup className="select-group">
                      <InputLabel htmlFor="card-type-bill-payment">Select a Card</InputLabel>
                      <Select
                        labelId="card-type-select-label"
                        id="card-type-bill-payment"
                        value={card}
                        label="Select Card"
                        onChange={handleCardChange}
                      >
                        <MenuItem value="Card 1">Card 1</MenuItem>
                        <MenuItem value="Card 2">Card 2</MenuItem>
                      </Select>
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="payment_amount">Amount</InputLabel>
                      <Input id="payment_amount" aria-describedby="my-helper-text" startAdornment={<InputAdornment position="start">$</InputAdornment>} />
                    </FormGroup>
                  </FormControl>
                </Box>
              </AccordionDetails>
              <AccordionActions>
                <Button sx={customMuiStyles.defaultBtn}>Cancel</Button>
                <Button sx={customMuiStyles.containedBtn} variant="contained">Pay now</Button>
              </AccordionActions>
            </Accordion>
            <Accordion disabled={disableActions} expanded={expanded === 'panel4'} onChange={handleAccChange('panel4')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <strong>Dispute a transaction via Customer Service</strong>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl>
                    <FormGroup className="select-group">
                      <InputLabel htmlFor="transaction-history">Transaction history</InputLabel>
                      <Select
                        labelId="transaction-history-select-label"
                        id="transaction-history"
                        value={transaction}
                        label="Select transaction history"
                        onChange={handleTransactionChange}
                      >
                        <MenuItem value="Transaction history 1">Transaction history 1</MenuItem>
                        <MenuItem value="Transaction history 2">Transaction history 2</MenuItem>
                        <MenuItem value="Transaction history 3">Transaction history 3</MenuItem>
                        <MenuItem value="Transaction history 4">Transaction history 4</MenuItem>
                      </Select>
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="dispute_reason">Reason</InputLabel>
                      <Input multiline={true} id="dispute_reason" aria-describedby="my-helper-text" />
                    </FormGroup>
                  </FormControl>
                </Box>
              </AccordionDetails>
              <AccordionActions>
                <Button sx={customMuiStyles.defaultBtn}>Cancel</Button>
                <Button sx={customMuiStyles.containedBtn} variant="contained">File a Dispute</Button>
              </AccordionActions>
            </Accordion>
            <Accordion disabled={disableActions} expanded={expanded === 'panel5'} onChange={handleAccChange('panel5')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5-content"
                id="panel5-header"
              >
                <strong>Managing Security</strong>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                    component="form"
                    sx={{
                      '& > :not(style,.accordian-button-cnt)': { m: 1, width: 'auto', display: 'block' },
                      '& > div > :not(style)': { display: 'inline-block' },
                      '& > div > label': { marginRight: '40px', minWidth: '155px' },
                      '& > .accordian-button-cnt': { justifyContent: 'flex-end', display: 'flex', gap: '10px' }
                    }}
                    noValidate
                    autoComplete="off"
                >
                  <FormControl>
                    <FormLabel>Mark Fraud History as:</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="moderate"
                      name="radio-buttons-group"
                      row
                    >
                      <FormControlLabel value="high" control={<Radio sx={customMuiStyles.radioBtn} />} label="High" />
                      <FormControlLabel value="moderate" control={<Radio sx={customMuiStyles.radioBtn} />} label="Moderate" />
                      <FormControlLabel value="no" control={<Radio sx={customMuiStyles.radioBtn} />} label="No" />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Freeze your card</FormLabel>
                    <FormGroup>
                      <Switch sx={customMuiStyles.toggleSwitch} />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Block your account</FormLabel>  
                    <FormGroup>
                      <Switch sx={customMuiStyles.toggleSwitch} defaultChecked />
                    </FormGroup>
                  </FormControl>
                  <div className='accordian-button-cnt'>
                    <Button sx={customMuiStyles.defaultBtn}>Cancel</Button>
                    <Button sx={customMuiStyles.containedBtn} variant="contained">Save Changes</Button>
                  </div>
                </Box>
              </AccordionDetails>
              <Divider />
              <AccordionDetails>
                <Box
                      component="form"
                      sx={{
                        '& > :not(style,.accordian-button-cnt)': { m: 1, width: 'auto', display: 'block' },
                        '& >div> :not(style)': { display: 'inline-block' },
                        '& >div>label': { marginRight: '20px' },
                        '& > .accordian-button-cnt': { justifyContent: 'flex-end', display: 'flex', gap: '10px' },
                        '& > :not(style,.accordian-button-cnt, p)': { display: 'inline-block' }
                      }}
                      noValidate
                      autoComplete="off"
                  >
                  <p>Forgot PIN</p>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="new_pin">Enter new PIN</InputLabel>
                      <Input id="new_pin" aria-describedby="my-helper-text" />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="re_new_pin">Re-enter new PIN</InputLabel>
                      <Input id="re_new_pin" aria-describedby="my-helper-text" />
                    </FormGroup>
                  </FormControl>
                  <div className='accordian-button-cnt'>
                    <Button sx={customMuiStyles.defaultBtn}>Cancel</Button>
                    <Button sx={customMuiStyles.containedBtn} variant="contained">Reset PIN</Button>
                  </div>
                </Box>
              </AccordionDetails>
              <Divider />
              <AccordionDetails>
                <Box
                      component="form"
                      sx={{
                        '& > :not(style,.accordian-button-cnt)': { m: 1, width: 'auto', display: 'block' },
                        '& >div> :not(style)': { display: 'inline-block' },
                        '& >div>label': { marginRight: '20px' },
                        '& > .accordian-button-cnt': { justifyContent: 'flex-end', display: 'flex', gap: '10px' },
                        '& > :not(style,.accordian-button-cnt, p)': { display: 'inline-block' }
                      }}
                      noValidate
                      autoComplete="off"
                  >
                  <p>Change PIN</p>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="old_pin">Enter old PIN</InputLabel>
                      <Input id="old_pin" aria-describedby="my-helper-text" />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="new_pin_change">Enter new PIN</InputLabel>
                      <Input id="new_pin_change" aria-describedby="my-helper-text" />
                    </FormGroup>
                  </FormControl>
                  <div className='accordian-button-cnt'>
                    <Button sx={customMuiStyles.defaultBtn}>Cancel</Button>
                    <Button sx={customMuiStyles.containedBtn} variant="contained">Change PIN</Button>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Typography variant="h5" component="h5" mt={5} mb={1} ml={2}>User Data Management</Typography>
              {/* <Accordion expanded={expanded === 'panelA'} onChange={handleAccChange('panelA')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panelA-content"
                  id="panelA-header"
                >
                  <div>
                    <strong>Reset Data to User Type</strong>
                    <div>Clear data and reset to a saved user type</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <AccordionActions sx={{'justifyContent': 'flex-start'}}>
                    <Button>Reset</Button>
                  </AccordionActions>
                </AccordionDetails>
              </Accordion> */}
            <Accordion expanded={expanded === 'panelB'} onChange={handleAccChange('panelB')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panelB-content"
                id="panelB-header"
              >
                <div>
                  <strong>Save Data state</strong>
                  <div>Save current user state as new user type</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                    <FormControl>
                    <FormGroup>
                      Save the actions and data state as a new user type.
                    </FormGroup>
                  </FormControl>
                    <FormControl>
                    <FormGroup>
                      <InputLabel htmlFor="usert_type_name">Enter the user type name</InputLabel>
                      <Input id="usert_type_name" aria-describedby="my-helper-text" />
                    </FormGroup>
                  </FormControl>
                </Box>
              </AccordionDetails>
              <AccordionActions>
                <Button sx={customMuiStyles.defaultBtn}>Cancel</Button>
                <Button sx={customMuiStyles.containedBtn} variant="contained">Save new user type</Button>
              </AccordionActions>
            </Accordion>
            <Accordion expanded={expanded === 'panelC'} onChange={handleAccChange('panelC')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panelC-content"
                id="panelC-header"
              >
                <div>
                  <strong>Review User Data Details</strong>
                  <div>Review current data state</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {userType === UserTypes.DEFAULT? <div>No data state to show for a new user</div> : <UserConfigTable userType={userType} />}  
              </AccordionDetails>
            </Accordion>
          </div>
        </NavBar>
    </Fragment>);
}
