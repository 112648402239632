import { VAResponseReply } from '../../api/send-message';
import { FC, createElement } from 'react';
// import { useVirtualAssistantTheme } from '../../app.theme';
import './assistant.scss';
import { ChatIcon } from '../../assets/chat-bot-icon';
export const TextItem: FC<VAResponseReply> = ({
  value,
  id,
  conversation_id
}) => {
  // const { specialColor } = useVirtualAssistantTheme();
  let data: any = [];
  if (value.description?.includes('<name>')) {
    data = value.description.split('<name>');
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {conversation_id === '1' && (
        <span style={{ paddingLeft: '8px' }}>
          <ChatIcon />
        </span>
      )}

      <div className="txtBubble round left" key={id}>
        <div className='TxtContainer'>
          <div className='Body'>
            { value.description?.includes('<name>') ? (<div>{data[0]}<strong>{data[1]}</strong>{data[2]}</div>) : value.description}
          </div>
        </div>
      </div>
    </div>
  );
};
