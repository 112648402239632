export const isMobileWidth = () => {
    return window.innerWidth < 600;
}

export const onlyUnique = (value: string, index: number, array: Array<string>) => {
    return array.indexOf(value) === index;
}

export const isFundTransferRequest = (message: string) => {
    const keywords = [
        "transfer", "send money", "fund", "pay", "load",
        "payment", "deposit", "withdraw", "move money", "add new", "add another", "link another", "link new"
    ];

    // Convert the message to lowercase to make the matching case-insensitive
    const lowerCaseMessage = message.toLowerCase();

    // Check if any keyword is present in the message
    return keywords.some(keyword => lowerCaseMessage.includes(keyword));
}

export const arraysEqual = (arr1:any[], arr2:any[]) => {
    // Check if arrays have the same length
    if (arr1.length !== arr2.length) return false;
  
    // Compare the arrays by stringifying them
    return JSON.stringify(arr1) === JSON.stringify(arr2);
}