import React from 'react';
import { useMenu, MenuProvider } from '@mui/base/useMenu';
import { useMenuItem } from '@mui/base/useMenuItem';
import { Popper } from '@mui/base/Popper';
import { useMenuButton } from '@mui/base/useMenuButton';
import { useDropdown, DropdownContext } from '@mui/base/useDropdown';
import clsx from 'clsx';
import { useTheme } from '@mui/system';
import variables from 'styles/constants.module.scss';

const Menu = React.forwardRef(function Menu(
    props: React.ComponentPropsWithoutRef<'ul'>,
    ref: React.Ref<HTMLUListElement>,
  ) {
    const { children, ...other } = props;
  
    const { open, triggerElement, contextValue, getListboxProps } = useMenu({
      listboxRef: ref,
    });
  
    return (
      <Popper open={open} anchorEl={triggerElement}>
        <ul className="menu-root" {...other} {...getListboxProps()}>
          <MenuProvider value={contextValue}>{children}</MenuProvider>
        </ul>
      </Popper>
    );
});
  
export const MenuItem = React.forwardRef(function MenuItem(
    props: React.ComponentPropsWithoutRef<'li'>,
    ref: React.Ref<any>,
  ) {
    const { children, onClick, ...other } = props;
  
    const { getRootProps, disabled, focusVisible } = useMenuItem({ rootRef: ref });
  
    const classes = {
      'focus-visible': focusVisible,
      'menu-item': true,
      disabled,
    };
  
    return (
      <li
        {...other}
        {...getRootProps({ onClick: onClick ?? (() => {}) })}
        className={clsx(classes)}
      >
        {children}
      </li>
    );
});
  
export const MenuButton = React.forwardRef(function MenuButton(
    props: React.PropsWithChildren<{}>,
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
  ) {
    const { getRootProps: getButtonProps } = useMenuButton({ rootRef: forwardedRef });
  
    return (
      <button type="button" {...props} {...getButtonProps()} className="button" />
    );
});

export const DropdownMenu = (props) => {
    const { contextValue: dropdownContextValue } = useDropdown();
    return (
        <DropdownContext.Provider value={dropdownContextValue}>
            <MenuButton>{props.label}</MenuButton>
            <Menu id="hooks-menu">{props.children}</Menu>
        </DropdownContext.Provider>
    )
}

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
};
  
const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};
  
const useIsDarkMode = () => {
    const theme = useTheme();
    return theme.palette.mode === 'dark';
}
  
export const Styles = () => {
    // Replace this with your app logic for determining dark mode
    const isDarkMode = useIsDarkMode();
  
    const styles = `
      .menu-root {
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        box-sizing: border-box;
        padding: 5px;
        margin: 10px 0;
        min-width: 200px;
        background: #fff;
        border: 1px solid ${grey[200]};
        border-radius: 0.75em;
        color: ${grey[900]};
        overflow: auto;
        outline: 0px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
      }
  
      .mode-dark .menu-root {
        background: ${grey[900]};
        border-color: ${grey[700]};
        color: ${grey[300]};
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
      }
  
      .menu-item {
        list-style: none;
        padding: 8px;
        border-radius: 0.45em;
        cursor: default;
        user-select: none;
      }
  
      .menu-item:last-of-type {
        border-bottom: none;
      }
  
      .menu-item:focus {
        background-color: ${grey[100]};
        color: ${grey[900]};
        outline: 0;
      }
  
      .mode-dark .menu-item:focus {
        background-color: ${grey[800]};
        color: ${grey[300]};
      }
  
      .menu-item.disabled {
        color: ${grey[400]};
      }
  
      .mode-dark .menu-item.disabled {
        color: ${grey[700]};
      }
  
      .button {
        z-index: 2;
        //position: relative;
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5;
        padding: 8px 16px;
        border-radius: 8px;
        margin: 0 10px;
        float: right;
        color: white;
        transition: all 150ms ease;
        cursor: pointer;
        background: ${isDarkMode ? grey[900] : '#fff'};
        border: 1px solid ${isDarkMode ? grey[700] : grey[200]};
        color: ${isDarkMode ? grey[200] : grey[900]};
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  
        &:hover {
          background: ${isDarkMode ? grey[800] : grey[50]};
          border-color: ${isDarkMode ? grey[600] : grey[300]};
        }
  
        &:active {
          background: ${isDarkMode ? grey[700] : grey[100]};
        }
  
        &:focus-visible {
          box-shadow: 0 0 0 4px ${isDarkMode ? blue[300] : blue[200]};
          outline: none;
        }
      }
      .MuiPopper-root {
        z-index: 10;
      }
      .MuiDialogActions-root {
        justify-content: center;
        -webkit-justify-content: center;
        padding: 0!important;
        button {
          flex: 1;
          padding:15px;
          background: ${variables.primaryColor};
          color: #fff;

          &:hover {
            background: ${variables.primaryColor};
          }
    
          &:active {
            background: #e47a7e;
          }
    
          &:focus-visible {
            box-shadow: 0 0 0 4px #e47a7e;
            outline: none;
          }
        }
      }
    `;
  
    // eslint-disable-next-line react/no-danger
    return <style dangerouslySetInnerHTML={{ __html: styles }} />;
}
