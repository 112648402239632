import { useState, useEffect, useCallback } from 'react';

const matchMediaQuery = (mediaQuery: string) => {
  return window.matchMedia(mediaQuery);
};

export const useMediaQuery = (mediaQueryExpected: string) => {
  const [isMediaQuery, setIsMediaQuery] = useState(
    matchMediaQuery(mediaQueryExpected).matches
  );

  const handleMediaQueryListener = useCallback(
    (mqe: MediaQueryList | MediaQueryListEvent) => {
      setIsMediaQuery(mqe.matches);
    },
    [setIsMediaQuery]
  );

  useEffect(() => {
    const mql = matchMediaQuery(mediaQueryExpected);
    handleMediaQueryListener(mql);
    mql.addEventListener('change', handleMediaQueryListener);
    return () => {
      return mql.removeEventListener('change', handleMediaQueryListener);
    };
  }, [mediaQueryExpected, handleMediaQueryListener]);

  return isMediaQuery;
};
