import * as React from 'react';
import { Fragment } from 'react';
import NavBar from 'components/nav-bar/nav-bar';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const Settings = () => {
  return ( 
    <Fragment>
        <NavBar>
          <h3 className="section-header">Settings</h3>
          <p style={{'padding': '10px'}}>Coming soon....</p>
        </NavBar>
    </Fragment>);
}

