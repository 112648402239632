import * as React from 'react';
// @ts-ignore
import CanvasJSReact from '@canvasjs/react-charts';

import './pie-chart-panel.scss';
import { DropdownMenu, MenuItem, Styles } from 'components/drop-down-menu/drop-down-menu';
import { TransactionCategories } from 'types';

export const PieChartPanel = (props: {data: any}) => {
  const [option, setOption] = React.useState<string>(TransactionCategories.MERCHANT);
  const [finalData, setFinalData] = React.useState<any>([["Trends", "Number"]]);
  let categories: string[] = [];

  const createHandleMenuClick = (menuItem: string) => {
    return () => {
      setOption(menuItem);
      const categoriesArr = props.data.map((item: any) => {
        let categoryVal;
        if (menuItem === TransactionCategories.MERCHANT) {
          categoryVal = item.merchant;
        } else if (menuItem === TransactionCategories.SPEND_CATEGORY) {
          categoryVal = item.labels[0];
        } else if (menuItem === TransactionCategories.RECURRENCE) {
          categoryVal = item.recurrence;
        } else if (menuItem === TransactionCategories.CITY) {
          categoryVal = item.location_structured.city;
        } else if (menuItem === TransactionCategories.STATE) {
          categoryVal = item.location_structured.state;
        }

        return categoryVal;
      });

      const onlyUnique = (value: string, index: number, array: Array<string>) => {
        return array.indexOf(value) === index;
      }
        
      categories = categoriesArr.filter(onlyUnique);

      let pieChartData;
      if (menuItem === TransactionCategories.MERCHANT) {
        let merchantData =  categories.map((cat, i) => {
          const categoryCount = props.data.filter((data: any) => {
            return data.merchant === cat;
          }).length;
          let itm = {name: cat, y:categoryCount};
          return itm;
        });
        pieChartData = merchantData;
      } else if (menuItem === TransactionCategories.SPEND_CATEGORY) {
        let spendData =  categories.map((cat, i) => {
          const categoryCount = props.data.filter((data: any) => {
            return data.labels[0] === cat;
          }).length;
          let itm = {name: cat, y:categoryCount};
          return itm;
        });
        pieChartData = spendData;
      } else if (menuItem === TransactionCategories.RECURRENCE) {
        let recurrenceData =  categories.map((cat, i) => {
          const categoryCount = props.data.filter((data: any) => {
            return data.recurrence === cat;
          }).length;
          let itm = {name: cat, y:categoryCount};
          return itm;
        });
        pieChartData = recurrenceData;
      } else if (menuItem === TransactionCategories.CITY) {
        let cityData =  categories.map((cat, i) => {
          const categoryCount = props.data.filter((data: any) => {
            return data.location_structured.city === cat;
          }).length;
          let itm = {name: cat, y:categoryCount};
          return itm;
        });
        pieChartData = cityData;
      } else if (menuItem === TransactionCategories.STATE) {
        let stateData =  categories.map((cat, i) => {
          const categoryCount = props.data.filter((data: any) => {
            return data.location_structured.state === cat;
          }).length;
          let itm = {name: cat, y:categoryCount};
          return itm;
        });
        pieChartData = stateData;
      }
      setFinalData(pieChartData);
    };
  };

  React.useEffect(() => {
    createHandleMenuClick(TransactionCategories.MERCHANT)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const explodePie =  (e: any) => {
    if(typeof (e.dataSeries.dataPoints[e.dataPointIndex].exploded) === "undefined" || !e.dataSeries.dataPoints[e.dataPointIndex].exploded) {
      e.dataSeries.dataPoints[e.dataPointIndex].exploded = true;
    } else {
      e.dataSeries.dataPoints[e.dataPointIndex].exploded = false;
    }
    e.chart.render();
  
  }

  const options = {
    exportEnabled: false,
    animationEnabled: true,
    legend:{
      cursor: "pointer",
      itemclick: explodePie
    },
    data: [{
      type: "pie",
      showInLegend: true,
      toolTipContent: "{name}: <strong>{y}</strong>",
      dataPoints: finalData
    }]
  }

  var CanvasJSChart = CanvasJSReact.CanvasJSChart;

  return (
    <React.Fragment>
      <DropdownMenu label="View Trends By">
        <MenuItem onClick={createHandleMenuClick(TransactionCategories.MERCHANT)}>{TransactionCategories.MERCHANT}</MenuItem>
        <MenuItem onClick={createHandleMenuClick(TransactionCategories.SPEND_CATEGORY)}>{TransactionCategories.SPEND_CATEGORY}</MenuItem>
        <MenuItem onClick={createHandleMenuClick(TransactionCategories.CITY)}>{TransactionCategories.CITY}</MenuItem>
        <MenuItem onClick={createHandleMenuClick(TransactionCategories.STATE)}>{TransactionCategories.STATE}</MenuItem>
        <MenuItem onClick={createHandleMenuClick(TransactionCategories.RECURRENCE)}>{TransactionCategories.RECURRENCE}</MenuItem> 
      </DropdownMenu>
      <div style={{textAlign: 'center', padding: '6px 0 0 115px'}}><strong>{option.toUpperCase()}</strong></div>
      
      <Styles />
      <div className="ChartSection" style={{'marginTop': '10px' }}>
        {finalData.length &&  
        <CanvasJSChart options = {options}
      />}
      </div>
    </React.Fragment>
  );
}
