import { VAResponseReply } from '../../api/send-message';
import { FC } from 'react';

export const ActionButton: FC<VAResponseReply> = ({ value, id, conversation_id }) => {
  const [displayText] = value.description?.split('=') ?? ['', ''];
  return (
    <div className="Suggestions" key={id} style={{'marginLeft': '20px'}}>
        <button className="ButtonFlat Button" name={displayText} onClick={value.action}>
          <span className="">{displayText}</span>
        </button>
    </div>
  );
};
