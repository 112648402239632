import { VAResponseReply } from '../../api/send-message';
import { FC, createElement } from 'react';
import './assistant.scss';

export const TransactionsTable: FC<VAResponseReply> = (props) => {
  return (
    <div className="txtBubble round left">
      <div className='TxtContainer'>
        <div className='Body'>
            Date&emsp;&emsp;&emsp;Memo&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;Amount
            {props.value?.transactions?.map((x: any) => (
              <span key={x.id}>
                <br />
                {`${
                  x.date &&
                  new Date(x.date).toLocaleString('en-us').split(',')[0]
                } ${x.memo.slice(0, 15)}${x.memo.length > 10 ? '...' : ''}  $${
                  x.amount / 100
                }`}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};
