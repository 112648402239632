import { FC, Fragment, useState } from 'react';
import { formatDate } from 'packages/date-formatter';
import { formatCurrency } from 'packages/currency-formatter';
import transactionsJson from "data/transactions.json";
import NavBar from 'components/nav-bar/nav-bar';
import VAScreen from 'components/va-screen/va-screen';
import { Box, Collapse, IconButton, Paper, Slide, Tab, Tabs, Typography } from '@mui/material';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchBar from "material-ui-search-bar";
import styles from './transactions-list.module.scss';
import { CustomTabPanel } from 'components/custom-tabs/custom-tabs';
import { PieChartPanel } from 'components/pie-chart-panel/pie-chart-panel';
import { Styles } from 'components/drop-down-menu/drop-down-menu';
import variables from 'styles/constants.module.scss';


import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { SearchFilters } from 'components/search-filters/search-filters';
import { TransactionCategories } from 'types';

export const TransactionList: FC<any> = () => {
  const [rows, setRows] = useState(transactionsJson);
  const [filtersCount, setFiltersCount] = useState(0);
  const [resultsCount, setResultsCount] = useState(rows.length);
  const [searched, setSearched] = useState<string>("");
  const filters = [TransactionCategories.MERCHANT, TransactionCategories.SPEND_CATEGORY, TransactionCategories.CITY, TransactionCategories.STATE, TransactionCategories.RECURRENCE];

  const [value, setValue] = useState(0);
  const [searchByVal] = useState<string>(TransactionCategories.MERCHANT);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = transactionsJson.filter((row) => {
      let propVal;
      //remove all conditions and keep default search by merchant
      if (searchByVal === 'date') {
        propVal = formatDate(row.parent_tx.date);
      } else if (searchByVal === TransactionCategories.MERCHANT) {
        propVal = row.merchant;
      } else if (searchByVal === 'amount') {
        propVal = formatCurrency(row.parent_tx.amount).toString();
      } else if (searchByVal === TransactionCategories.RECURRENCE) {
        propVal = row.recurrence;
      }  else if (searchByVal === TransactionCategories.SPEND_CATEGORY) {
        propVal = row.labels[0];
      }
      return propVal?.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <NavBar>
        <VAScreen />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Transactions" {...a11yProps(0)} />
            <Tab label="My Trends" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Styles />
            <div style={{'display': 'flex'}}>
              <SearchBar
                className={styles.searchBar}
                placeholder={`Search by ${searchByVal}`}
                value={searched}
                onChange={(searchVal: string) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              
              <Button variant="outlined" className="button" onClick={handleClickOpen}>
                Filters
              </Button>
            </div>
            <BootstrapDialog
              fullScreen
              TransitionComponent={Transition}
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={true}
              style={open ? {'display': 'block'}: {'display': 'none'}}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Filters {filtersCount? `(${filtersCount})` : ''}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute!important',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <SearchFilters 
                  filters={filters} 
                  data={transactionsJson} 
                  setFinalRecords={setRows} 
                  setResultsCount={setResultsCount}
                  setFiltersCount={setFiltersCount}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Show {resultsCount} Results
                </Button>
              </DialogActions>
            </BootstrapDialog>
            <TableContainer component={Paper} className={styles.transactionTableCnt}>
              <Table className={styles.transactionTable} stickyHeader aria-label="collapsible sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.header}>When</TableCell>
                    <TableCell className={styles.header}>Where</TableCell>
                    <TableCell className={styles.header}>Amount</TableCell>
                    <TableCell className={styles.header}>Balance</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((transaction) => (
                    <Row key={transaction.transaction_id} row={transaction} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PieChartPanel data={transactionsJson} />
        </CustomTabPanel>
      </NavBar>
    </Fragment>
  );
};


function Row(props: any) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset!important' } }}>
        <TableCell className={styles.cell} scope="row">
          {formatDate(row.parent_tx.date)}
        </TableCell>
        <TableCell className={styles.cell + ' ' + styles.merchantName}><div><a href={`//${row.website!}`} target="_blank" rel="noreferrer"><img alt="logo" width="30" height="30" src={row.logo} /></a>{row.merchant}</div></TableCell>
        <TableCell className={styles.cell} style={{color: row.parent_tx.entry_type === 'outgoing'? `${variables.primaryColor}` : 'green'}}>
                  {formatCurrency(row.parent_tx.amount)}</TableCell>
        <TableCell className={styles.cell}>{formatCurrency(row.parent_tx.amount)}</TableCell>
        <TableCell className={styles.cell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.header}>Location</TableCell>
                    <TableCell className={styles.header}>Reccurrence</TableCell>
                    <TableCell className={styles.header}>Classification</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.merchant_id}>
                    <TableCell className={styles.cell} scope="row">
                      <a className={styles.link} href={row.location_structured?.google_maps_url} target="_blank" rel="noreferrer">Map link</a>
                    </TableCell>
                    <TableCell className={styles.cell}>{row.recurrence}</TableCell>
                    <TableCell className={styles.cell}>{row.labels[0]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});