import { cardActions, globalActions, mainMenuActions, notificationActions, overdraftActions, rewardsActions, securityActions, submitButtonText } from "./actions";

//Eventhandlers need to be rebinded as they are lost from stored chats since localstorage doesn't retain functions and removes them from stored data

export const rebindActions = (storedChat: [], setWelcomeMsg:  React.Dispatch<React.SetStateAction<any[]>>, setUserTxt: React.Dispatch<React.SetStateAction<string>>) => {
    if (storedChat.length) {
        storedChat.forEach((element: any) => {
            if (element.type === "ACTION_BUTTON" ) {
                if (element.value.description === "Cards=") {
                    element.value["action"] = () => {
                        console.log('Cards!!');
                        setWelcomeMsg(cardActions(setUserTxt, setWelcomeMsg));
                    }
                } else if (element.value.description === "Global=") {
                    element.value["action"] = () => {
                        console.log('Global!!');
                        setWelcomeMsg(globalActions(setUserTxt, setWelcomeMsg));
                    }
                } else if (element.value.description === "Notifications=") {
                    element.value["action"] = () => {
                        console.log('Notifications!!');
                        setWelcomeMsg(notificationActions(setUserTxt, setWelcomeMsg));
                    }
                } else if (element.value.description === "Rewards=") {
                    element.value["action"] = () => {
                        console.log('Rewards!!');
                        setWelcomeMsg(rewardsActions(setUserTxt, setWelcomeMsg));
                    }
                } else if (element.value.description === "Overdraft=") {
                    element.value["action"] = () => {
                        console.log('Overdraft!!');
                        setWelcomeMsg(overdraftActions(setUserTxt, setWelcomeMsg));
                    }
                } else if (element.value.description === "Security=") {
                    element.value["action"] = () => {
                        console.log('Security!!');
                        setWelcomeMsg(securityActions(setUserTxt, setWelcomeMsg));
                    }
                }else if (element.value.description === "Menu=") {
                    element.value["action"] = () => {
                        console.log('Menu!!');
                        setWelcomeMsg(mainMenuActions(setUserTxt, setWelcomeMsg));
                    }
                } else { //for all action buttons which don't have sub-categories and directly sends text to bot,
                    element.value["action"] = () => {  // rebind listner for Yes for plain transfer
                        submitButtonText(element.value.description.split('=')[0], setUserTxt);
                    }
                }
            }
        });
      }
}