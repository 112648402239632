import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PlainLinkLogo from 'images/plaid-link.png';
import { Box, Card, CardContent } from '@mui/material';
import PlaidButton from 'components/plaid/plaid-button';
import PlaidBankSearch from './plaid-bank-search';

export const PlaidDialogContent: React.FC<{setOpenPlaid: React.Dispatch<React.SetStateAction<boolean>>}> = ({setOpenPlaid}) => {
  const [showBanks, setShowbanks] = React.useState(false);

  const handleClosePlaid = () => {
    setOpenPlaid(false);
  };

  const handlePlaidSteps = () => {
    setShowbanks(true);
  }

  return (
    <React.Fragment>
      <div style={{width: '360px', height: '591px'}}>
        <IconButton
          aria-label="close"
          onClick={handleClosePlaid}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        {!showBanks ? (<div>
          <DialogContent>
            <img src={PlainLinkLogo} alt="PlainLinkLogo" width="300" style={{margin: '20px auto'}} />
            <Typography variant="h5" component="h5" style={{fontSize: '20px', textAlign: 'center'}}>
              Your bank account is not linked. Please connect your bank account using <strong>Plaid</strong> for transferring funds.
            </Typography>
            <Box sx={{ minWidth: 275 }}>
              <Card variant="outlined" sx={{marginTop: '40px'}}>
                  <CardContent>
                      <Typography component="div" mt={2}>
                          <div><strong>Connect in seconds</strong></div>
                          <span>8000+ apps trust plaid to quickly connect to financial institutions</span>
                      </Typography>
                      <Typography component="div" mt={2}>
                          <div><strong>Keep your data safe</strong></div>
                      <span>Plaid uses best-in-class encryption to help protect your data</span>
                      </Typography>
                  </CardContent>
              </Card>
            </Box>
          </DialogContent>
          <DialogActions sx={{display: 'block'}}>
            <PlaidButton text="Continue" handleClick={handlePlaidSteps} />
          </DialogActions>
        </div>) : (
          <PlaidBankSearch setShowbanks={setShowbanks} setOpenPlaid={setOpenPlaid}  />
        )
      }
      </div>
    </React.Fragment>
  );
}
