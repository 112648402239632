import { cardActions, mainMenuActions, overdraftActions, rewardsActions } from "components/va-screen/actions";
import { useState } from "react";
import { UpdateTypes } from "types";

export interface VAResponse {
  replies: VAResponseReply[];
  conversation_id?: string;
  intent?: string;
}

export interface ValueType {
  description?: string;
  transactions?: any[];
  subscribed_plan_response?: { subscription: any; plan: any };
  action? : () => void;
}

export interface VAResponseReply {
  id?: string;
  type:
    | 'TEXT'
    | 'LINK'
    | 'PDF'
    | 'TRANSACTIONS_TABLE'
    | 'FEE_TABLE'
    | 'MINI_TILE'
    | 'ACTION_BUTTON';
  value: ValueType;
  from?: 'bot' | 'user';
  intent?: string;
  conversation_id?: string;
  storedInDB: boolean;
}

export const useMockVirtualAssistantMessageResponse = (setWelcomeMsg: any, setUserTxt: React.Dispatch<React.SetStateAction<string>>, account_updates: any = {} ) => {
  const displayName = JSON.parse(localStorage.getItem('okta-token-storage') || '{}').idToken?.claims?.name.split(' ')[0] || 'User';
  const menu = mainMenuActions(setUserTxt, setWelcomeMsg);
  menu.shift();

  let updatesMsgs = []
  if (account_updates) {
    account_updates[UpdateTypes.CARD_SHIPPING_STATUS]?.forEach((value: any) => {
      const message =  {
        from: 'bot',
        type: 'TEXT',
        value: {
          description:
            `Your card ending with ${value["card-number-last4"]} is ${value.shippingStatus}`
        },
        conversation_id: '1',
        storedInDB: false
      };
      updatesMsgs.push(message);
    })

    if (account_updates[UpdateTypes.DEPOSIT_DETAILS]) {
      const expectedNextDeposit = account_updates[UpdateTypes.DEPOSIT_DETAILS].expectedNextDeposit;
      const latestDeposit = account_updates[UpdateTypes.DEPOSIT_DETAILS].latestDeposit;
      const message =  {
        from: 'bot',
        type: 'TEXT',
        value: {
          description:
            `The status of your last deposit for ${latestDeposit.amount} ${latestDeposit.currency} is ${latestDeposit.status}. Your expected next deposit date is ${new Date(expectedNextDeposit)}.`
        },
        conversation_id: '1',
        storedInDB: false
      };
      updatesMsgs.push(message);
    }

    account_updates[UpdateTypes.OFFERS]?.forEach((value: any) => {
      const message =  {
      from: 'bot',
      type: 'TEXT',
      value: {
        description:
          `You have ${value["reward-points"]} ${value["reward-program-name"]} expiring on ${new Date(value["reward-expiry"])}.`
      },
      conversation_id: '1',
      storedInDB: false
      };
      updatesMsgs.push(message);
    })

    //rewards ..coming soon
  }


  const introChats = [
    {
      from: 'bot',
      type: 'TEXT',
      value: {
        description:
          `Welcome <name>${displayName}<name> to the Dedun SimBank virtual assistant. How can I help you?`
      },
      conversation_id: '1',
      storedInDB: false
    },
    ...menu,
    ...updatesMsgs
  ];

  setWelcomeMsg(() => {
    return introChats;
  });
}
