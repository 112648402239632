import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './search-filters.module.scss';
import SearchBar from 'material-ui-search-bar';
import { useEffect, useRef, useState } from 'react';
import { onlyUnique } from 'utils/utils';
import variables from 'styles/constants.module.scss';
import { TransactionCategories, TransactionData } from 'types';

export const SearchFilters = (props: any) => {
    const [eleKey, setEleKey] = useState(Math.random());
    const { filters, data, setFinalRecords, setResultsCount } = props;
    const [searched, setSearched] = useState<string>("");

    let merchants = data.map((item: TransactionData) => item.merchant).filter(onlyUnique);
    let spendCategories = data.map((item: TransactionData) => item.labels[0]).filter(onlyUnique);
    let cites = data.map((item: TransactionData) => item.location_structured.city).filter(onlyUnique);
    let states = data.map((item: TransactionData) => item.location_structured.state).filter(onlyUnique);
    let recurrences = data.map((item: TransactionData) => item.recurrence).filter(onlyUnique);

    const [merchantOptions, setMerchantOptions] = useState(merchants);
    const [spendCategoryOptions, setSpendCategoryOptions] = useState(spendCategories);
    const [cityOptions, setCityOptions] = useState(cites);
    const [stateOptions, setStateOptions] = useState(states);
    const [recurrenceOptions, setRecurrenceOptions] = useState(recurrences);

    const requestSearch = (searchedVal: string, filter: string) => {
        if (filter === TransactionCategories.MERCHANT) {
            setMerchantOptions(merchants.filter((option: string) => {
                return option?.toLowerCase().includes(searchedVal.toLowerCase());
              }));
        } else if (filter === TransactionCategories.SPEND_CATEGORY) {
            setSpendCategoryOptions(spendCategories.filter((option: string) => {
                return option?.toLowerCase().includes(searchedVal.toLowerCase());
              }));
        }  else if (filter === TransactionCategories.CITY) {
            setCityOptions(cites.filter((option: string) => {
                return option?.toLowerCase().includes(searchedVal.toLowerCase());
              }));
        }  else if (filter === TransactionCategories.STATE) {
            setStateOptions(states.filter((option: string) => {
                return option?.toLowerCase().includes(searchedVal.toLowerCase());
              }));
        } else if (filter === TransactionCategories.RECURRENCE) {
            setRecurrenceOptions(recurrences.filter((option: string) => {
                return option?.toLowerCase().includes(searchedVal.toLowerCase());
              }));
        } 
      };
    
      const cancelSearch = (filter: string) => {
        setSearched("");
        requestSearch(searched, filter);
      };

      useEffect(() => {

      }, []);
      const filteredRows = useRef(data);

      const appliedFilters = useRef<any>({
        "merchant": [],
        "spend category": [],
        "city": [],
        "state": [],
        "recurrence": []
      });

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, filter: string) => {
        const id = event.target.id;
        const key = filter.toLocaleLowerCase();
        if (checked) {
            appliedFilters.current[key].push(id);
        } else {
            const index = appliedFilters.current[key].indexOf(id);
            appliedFilters.current[key].splice(index, 1);
        }
        filterData();
        props.setFiltersCount(appliedFilters.current['merchant'].length + appliedFilters.current['spend category'].length + appliedFilters.current['city'].length + appliedFilters.current['state'].length + appliedFilters.current['recurrence'].length)
    };

    const filterData = () => {
        console.log('appliedFilters', appliedFilters);

        filteredRows.current = data.filter((item: TransactionData) => {
            if (appliedFilters.current['merchant'].length) {
                return (appliedFilters.current['merchant'].indexOf(item.merchant) > -1);
            } else {
                return true;
            }
        }).filter((item: TransactionData) => {
            if (appliedFilters.current['spend category'].length) {
                return (appliedFilters.current['spend category'].indexOf(item.labels[0]) > -1);
            } else {
                return true;
            }
        }).filter((item: TransactionData) => {
            if (appliedFilters.current['city'].length) {
                return (appliedFilters.current['city'].length && appliedFilters.current['city'].indexOf(item.location_structured.city) > -1);
            } else {
                return true;
            }
        }).filter((item: TransactionData) => {
            if (appliedFilters.current['state'].length) {
                return (appliedFilters.current['state'].length && appliedFilters.current['state'].indexOf(item.location_structured.state) > -1)
            } else {
                return true;
            }
        }).filter((item: TransactionData) => {
            if (appliedFilters.current['recurrence'].length) {
                return (appliedFilters.current['recurrence'].length && appliedFilters.current['recurrence'].indexOf(item.recurrence) > -1)
            } else {
                return true;
            }
        });

        setResultsCount(filteredRows.current.length);
        setFinalRecords(filteredRows.current);
    }

    const clearFilters = () => {
        appliedFilters.current['merchant'] = [];
        appliedFilters.current['spend category'] = [];
        appliedFilters.current['city'] = [];
        appliedFilters.current['state'] = [];
        appliedFilters.current['recurrence'] = [];
        props.setFiltersCount(appliedFilters.current['merchant'].length + appliedFilters.current['spend category'].length + appliedFilters.current['city'].length + appliedFilters.current['state'].length + appliedFilters.current['recurrence'].length)
        setEleKey(Math.random());
        filterData();
    }

    return (
        <div key={eleKey} id="searchFilter">
            <Styles />
            <Link href="#" onClick={clearFilters} style={{'marginBottom':'15px', 'display': 'block'}}>Clear filters</Link>
            {filters.map((filter: string, i: number) => (
                <Accordion key={`accordian_${i}`} defaultExpanded={(i===filters.length - 1) ? true: false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${i+1}-content`}
                        id={`panel${i+1}-header`}
                        className={styles.filterSummary}
                    >
                        {filter} <strong className={styles.filterCount}>{appliedFilters.current[filter.toLowerCase()].length ? appliedFilters.current[filter.toLowerCase()].length + ' selected' : ''} </strong>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SearchBar
                            className={styles.searchBar}
                            placeholder={`Search by ${filter}`}
                            value={searched}
                            onChange={(searchVal: string) => requestSearch(searchVal, filter)}
                            onCancelSearch={() => cancelSearch(filter)}
                        />
                        <FormGroup key={i}>
                            {data.length &&
                                filter === TransactionCategories.MERCHANT ? (merchantOptions.map((name: string)=> <FormControlLabel key={filter+name} control={<Checkbox id={name} onChange={(e, checked) => onFilterChange(e, checked, filter)} />} label={name} />)    
                            ) : filter === TransactionCategories.SPEND_CATEGORY ? (spendCategoryOptions.map((name: string)=> <FormControlLabel key={filter+name} control={<Checkbox id={name} onChange={(e, checked) => onFilterChange(e, checked, filter)} />} label={name} />)  
                            ) : filter === TransactionCategories.CITY ? (cityOptions.map((name: string)=> <FormControlLabel key={filter+name} control={<Checkbox id={name} onChange={(e, checked) => onFilterChange(e, checked, filter)} />} label={name} />)  
                            ) : filter === TransactionCategories.STATE ? (stateOptions.map((name: string)=> <FormControlLabel key={filter+name} control={<Checkbox id={name} onChange={(e, checked) => onFilterChange(e, checked, filter)} />} label={name} />) 
                            ) : filter === TransactionCategories.RECURRENCE ? (recurrenceOptions.map((name: string)=> <FormControlLabel key={filter+name} control={<Checkbox id={name} onChange={(e, checked) => onFilterChange(e, checked, filter)} />} label={name} />) 
                            ) : (null)
                            }
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    )
}

const Styles = () => {
    const styles = `
    #searchFilter{
       .MuiButtonBase-root {
        display: flex;
       }
       span.MuiButtonBase-root {
        padding: 0 16px;
       }
       .MuiFormControlLabel-root {
        padding: 0;
        margin : 16px 0 0 0;
       }
       .MuiCheckbox-root.Mui-checked {
        color: ${variables.primaryColor};
       }
    }
    `;
    return <style dangerouslySetInnerHTML={{ __html: styles }} />;
}