import { FC, createElement } from 'react';

export const UserIcon: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M11.7601 27.3805C13.3964 25.2688 15.9251 23.9222 19.0008 23.9222C22.0765 23.9222 24.6052 25.2688 26.2414 27.3805M11.7601 27.3805C13.7016 29.0594 16.2326 30.075 19.0008 30.075C21.769 30.075 24.3 29.0594 26.2414 27.3805M11.7601 27.3805C9.41171 25.3497 7.92578 22.3484 7.92578 19C7.92578 12.8834 12.8842 7.92499 19.0008 7.92499C25.1173 7.92499 30.0758 12.8834 30.0758 19C30.0758 22.3484 28.5899 25.3497 26.2414 27.3805M22.6924 16.5389C22.6924 18.5777 21.0396 20.2305 19.0008 20.2305C16.9619 20.2305 15.3091 18.5777 15.3091 16.5389C15.3091 14.5 16.9619 12.8472 19.0008 12.8472C21.0396 12.8472 22.6924 14.5 22.6924 16.5389Z"
        stroke="#9049A6"
        stroke-width="2.85"
        stroke-linejoin="round"
      />
      <path
        d="M7.76012 23.3805C9.39637 21.2688 11.9251 19.9222 15.0008 19.9222C18.0765 19.9222 20.6052 21.2688 22.2414 23.3805M7.76012 23.3805C9.70161 25.0594 12.2326 26.075 15.0008 26.075C17.769 26.075 20.3 25.0594 22.2414 23.3805M7.76012 23.3805C5.41171 21.3497 3.92578 18.3484 3.92578 15C3.92578 8.88343 8.88423 3.92499 15.0008 3.92499C21.1173 3.92499 26.0758 8.88343 26.0758 15C26.0758 18.3484 24.5899 21.3497 22.2414 23.3805M18.6924 12.5389C18.6924 14.5777 17.0396 16.2305 15.0008 16.2305C12.9619 16.2305 11.3091 14.5777 11.3091 12.5389C11.3091 10.5 12.9619 8.84721 15.0008 8.84721C17.0396 8.84721 18.6924 10.5 18.6924 12.5389Z"
        stroke="#222222"
        stroke-width="2.85"
        stroke-linejoin="round"
      />
    </svg>
  );
};
