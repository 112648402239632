import React, { FC, Fragment, useState } from 'react';
import PlaidLogo from 'images/plaid_logo.svg'
import BankLogo from 'images/bank_logo.png';
import BankCheckList from 'images/bank_checklist.png';
import { Box, Card, DialogActions, IconButton, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaidButton from 'components/plaid/plaid-button';
import PlaidLinkAccount from './plaid-link-account';


// Styled component for header
const Header = styled(Box)({
    backgroundColor: '#1e57a5', // Blue background for the header
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '8px 8px 0 0',
  });

const PlaidBankLogin: FC<{setShowPlaidLogin: React.Dispatch<React.SetStateAction<boolean>>, bankName: string, setOpenPlaid: React.Dispatch<React.SetStateAction<boolean>> }> = (props) => {
  const [showPlaidLinkAccount, setShowPlaidLinkAccount] = useState(false);

  const goBack = () => {
    props.setShowPlaidLogin(false);
  }

  const gotoBank = () => {
    setShowPlaidLinkAccount(true);
  }

  return (
  <Fragment>
    {showPlaidLinkAccount ? (<PlaidLinkAccount bankName={props.bankName } setShowPlaidLinkAccount={setShowPlaidLinkAccount} setOpenPlaid={props.setOpenPlaid} />) : (
    <div style={{ padding: '20px' }}>
      <IconButton aria-label="back" onClick={goBack} sx={{position: 'absolute', left: '5px', top: '10px'}}>
        <ArrowBackIcon />
      </IconButton>
      <img src={PlaidLogo} alt="PlainLinkLogo" width="54" style={{margin: 'auto', display: 'block'}} />
      <img src={BankLogo} alt="BankLogo" width="100" style={{margin: '30px auto', display: 'block'}} />
      <h3 style={{textAlign: 'center'}}>Log in at {props.bankName}</h3>
      <Card style={{ width: '300px', borderRadius: '10px', overflow: 'hidden', marginBottom: '25px' }}>
        <Header>{props.bankName}</Header>
        <img src={BankCheckList} alt="BankCheckList" width="250" style={{margin: '30px auto', display: 'block'}} />
      </Card>
      <DialogActions sx={{display: 'block'}}>
        <PlaidButton text="Continue to log in" handleClick={gotoBank} />
      </DialogActions>
    </div>)
    }
  </Fragment>
  );
};

export default PlaidBankLogin;
