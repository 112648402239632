import { FC } from 'react';

export const SendIcon: FC<{ color: string }> = ({ color }) => {
  return (
    <svg width="50px" height="50px" viewBox="0 -0.5 17 17">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M17,1.042 L11.436,14.954 L7.958,11.477 L8.653,13.563 L7.03,14.958 L7.03,11.563 L14.984,3.375 L6.047,9.969 L1,8.694 L17,1.042 Z"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};
