import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

function createData(
    userType: string,
    overdraft: string,
    existingCard: string,
    feePlan: string,
    shippedNewCard: string,
    rewards: string,
    spendingPower: string,
    fraudHistory: string,
    freezeCard: string,
    blockAccount: string,
  ) {
    return { userType, overdraft, existingCard, feePlan, shippedNewCard, rewards, spendingPower, fraudHistory, freezeCard, blockAccount};
  }
  
  const rows = [
    createData('Reward Seeker', 'Opted out, eligible', 'Yes', 'Monthly Plan', 'No', 'Yes', 'Standard spending power', 'No', 'No', 'No'),
  ];

export default function UserConfigTable(props: {userType: string}) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User Type</TableCell>
              <TableCell align="right">Overdraft</TableCell>
              <TableCell align="right">Existing Card</TableCell>
              <TableCell align="right">Fee Plan</TableCell>
              <TableCell align="right">Shipped New Card</TableCell>
              <TableCell align="right">Rewards</TableCell>
              <TableCell align="right">Spending Power</TableCell>
              <TableCell align="right">Fraud History</TableCell>
              <TableCell align="right">Freeze Card</TableCell>
              <TableCell align="right">Block Account</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={props.userType}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {props.userType}
                </TableCell>
                <TableCell align="right">{row.overdraft}</TableCell>
                <TableCell align="right">{row.existingCard}</TableCell>
                <TableCell align="right">{row.feePlan}</TableCell>
                <TableCell align="right">{row.shippedNewCard}</TableCell>
                <TableCell align="right">{row.rewards}</TableCell>
                <TableCell align="right">{row.spendingPower}</TableCell>
                <TableCell align="right">{row.fraudHistory}</TableCell>
                <TableCell align="right">{row.freezeCard}</TableCell>
                <TableCell align="right">{row.blockAccount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }