import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import DoorBackOutlinedIcon from '@mui/icons-material/DoorBackOutlined';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, CssBaseline, Paper, Toolbar } from '@mui/material';
import { useEffect } from 'react';
import BankLogo from 'images/bank-logo.png';
import './bottom-nav.scss';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import variables from 'styles/constants.module.scss';

export default function BottomNav(props: any) {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(pathname.split('/')[1]);
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    (ref.current as HTMLDivElement).ownerDocument.body.scrollTop = 0;
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
    &.Mui-selected {
      color: ${variables.primaryColor};
    }
  `);

  const styles: {[key: string]: any} = {
    navigationSection: {
      width: 'auto',
      backgroundColor: '#D9D9D9'
    }
  }

  const logout = () => {
    sessionStorage.clear();
    props.oktaAuth.signOut();
  }

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
        <CssBaseline />
        <Toolbar>
          <img src={BankLogo} alt="logo" width="200"/>
          <span className="heading">A Simulated Banking Experience</span>
        </Toolbar>
        {props.children}
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation  showLabels sx={styles.navigationSection} value={value} onChange={handleChange}>
                <BottomNavigationAction
                    label="Home"
                    value="home"
                    icon={<HomeIcon />}
                    component={Link} to="/"
                />
                <BottomNavigationAction
                    label="Transactions"
                    value="transactions"
                    icon={<ReceiptLongIcon />}
                    component={Link} to="/transactions"
                />
                <BottomNavigationAction
                    label="Settings"
                    value="settings"
                    icon={<SettingsIcon />}
                    component={Link} to="/settings"
                />
                <BottomNavigationAction
                    label="Back Door"
                    value="back-door"
                    icon={<DoorBackOutlinedIcon />}
                    component={Link} to="/back-door"
                />
                <BottomNavigationAction
                    label="Profile"
                    value="profile"
                    icon={<PersonIcon />}
                    component={Link} to="/profile"
                />
                <BottomNavigationAction
                    label="Logout"
                    value="logout"
                    icon={<LogoutIcon />}
                    onClick={logout}
                />
            </BottomNavigation>
        </Paper>
    </Box>
  );
}
